import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/LoginPage.css';
import { BASE_URL, LOGIN } from '../utils';
import { useAuth } from '../AuthContext';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { setAuth } = useAuth();

    const validateForm = () => {
        let tempErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        tempErrors.email = emailRegex.test(email) ? "" : "Email is not valid.";
        tempErrors.password = password ? "" : "Password is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                const response = await fetch(BASE_URL + LOGIN, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email, password })
                });
                const data = await response.json();
                if (response.ok) {
                    const { id, role, name, email } = data.user;
                    localStorage.setItem('isLoggedIn', 'true');
                    localStorage.setItem('id', id.toString());
                    localStorage.setItem('userRole', role);
                    localStorage.setItem('userName', name);
                    localStorage.setItem('userEmail', email);
                    setAuth({ userId: id, role, isLoggedIn: true});
                    handleRoleBasedRedirect(role);
                } else {
                    setErrors(errors => ({ ...errors, login: data.message || 'Login failed. Please try again.' }));
                }
            } catch (error) {
                console.error('Login error:', error);
                setErrors(errors => ({ ...errors, login: 'An unexpected error occurred.' }));
            }
        }
    };
    const handleRoleBasedRedirect = (role) => {
        const roleMap = {
            'Administrator': '/admin-dashboard',
            'Pharmacist': '/pharmacist-dashboard',
            'Healthcare Provider': '/healthcareprovider-dashboard',
            'Health Administrator': '/healthcareadministrator-dashboard',
            'Patient': '/user-dashboard'
        };

        const dashboardPath = roleMap[role];
        if (dashboardPath) {
            navigate(dashboardPath);
        } else {
            console.error('Unhandled role:', role);
            navigate('/');
        }
    };
    return (
        <div className="login-container">
            <form onSubmit={handleSubmit} noValidate>
                <h2>Login</h2>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
                    {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
                    {errors.password && <p className="error">{errors.password}</p>}
                </div>
                {errors.login && <p className="error">{errors.login}</p>}
                <div className="form-actions">
                    <button type="submit">Login</button>
                    <p className="forgot-password">
                        Forgot your password? <Link to="/forgot-password">Reset it here</Link>
                    </p>
                    <p className="signup">
                        Don't have an account? <Link to="/register">Sign up</Link>
                    </p>
                </div>
            </form>
        </div>
    );
};

export default LoginPage;