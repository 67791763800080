import React, { useState, useEffect } from 'react';
import '../../styles/DashboardHealthCareAdministrator.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { BASE_URL } from '../../utils';

const StaffCoordinationComponent = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const response = await fetch(`${BASE_URL}/users`);
    const data = await response.json();
    const filteredUsers = data.users.filter(user => user.role === 'Healthcare Provider' || user.role === 'Pharmacist');
    setUsers(filteredUsers);
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedUser(null);
    setIsTouched(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIsTouched(true);
    setSelectedUser(prev => ({ ...prev, [name]: value }));
  };

  const saveChanges = async () => {
    if (!isTouched) return;

    const response = await fetch(`${BASE_URL}/users/${selectedUser.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(selectedUser),
    });

    if (response.ok) {
      fetchUsers();
      closeModal();
    } else {
      alert("Failed to update user.");
    }
  };

  const removeUser = async (id) => {
    const response = await fetch(`${BASE_URL}/users/${id}`, {
      method: 'DELETE',
    });

    if (response.ok) {
      setUsers(users.filter(user => user.id !== id));
    } else {
      alert("Failed to delete user.");
    }
  };

  return (
    <div className="staff-coordination">
      <h2>Staff Coordination</h2>
      <ul className="staff-list">
        {users.map(user => (
          <li key={user.id} className="staff-item" style={{ textAlign: "left" }}>
            <div className="staff-details">
              <h3>{user.name}</h3>
              <p>Email: {user.email}</p>
              <p>Date of Birth: {new Date(user.date_of_birth).toLocaleDateString()}</p>
              <p>Address: {user.address}</p>
              <p>Phone: {user.phone_number}</p>
              <p>Role: {user.role}</p>
            </div>
            <div className="staff-actions">
              <button className="edit-btn" onClick={() => openModal(user)}>Edit</button>
              <button className="remove-btn" onClick={() => removeUser(user.id)}>Remove</button>
            </div>
          </li>
        ))}
      </ul>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          className="modal-content-healthcareadmin"
          overlayClassName="modal-overlay"
          onRequestClose={closeModal}
          contentLabel="Edit User Modal"
        >
          <h2 style={{ display: "flex", justifyContent: "center" }}>Edit Staff Member</h2>
          <form className="modal-form" onSubmit={(e) => e.preventDefault()}>
            <label>
              Name:
              <input type="text" name="name" required value={selectedUser.name} onChange={handleInputChange} />
            </label>
            <label>
              Email:
              <input type="email" name="email" required value={selectedUser.email} onChange={handleInputChange} />
            </label>
            <label>
              Date of Birth:
              <input type="date" name="date_of_birth" required value={selectedUser.date_of_birth.split('T')[0]} onChange={handleInputChange} />
            </label>
            <label>
              Address:
              <input type="text" name="address" required value={selectedUser.address} onChange={handleInputChange} />
            </label>
            <label>
              Phone Number:
              <input type="text" name="phone_number" required value={selectedUser.phone_number} onChange={handleInputChange} />
            </label>
            <div className="modal-buttons">
              <button type="button" onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </button>
              <button type="submit" onClick={saveChanges} disabled={!isTouched}>
                <FontAwesomeIcon icon={faSave} /> Save Changes
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default StaffCoordinationComponent;
