import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AppointmentsComponent.css';
import { BASE_URL } from '../utils';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTimes, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import "react-datepicker/dist/react-datepicker.css";


const AppointmentsComponent = () => {
    const [appointments, setAppointments] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
    const [rescheduleModalIsOpen, setRescheduleModalIsOpen] = useState(false);
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const id = localStorage.getItem('id');
    const hpId = localStorage.getItem('provider_id');
    const [users, setUsers] = useState([]);
    const [minTime, setMinTime] = useState("00:00");


    useEffect(() => {
        fetchUsersAndAppointments();
        if (isToday(selectedDate)) {
          const now = new Date();
          setMinTime(formatTime(now));
        } else {
          setMinTime("00:00"); // Reset minTime to default value
        }
    }, [selectedDate]);
    
    const fetchUsersAndAppointments = async () => {
        try {
            const usersResponse = await axios.get(`${BASE_URL}/users`);
            const usersData = usersResponse.data;
            setUsers(usersData.users);
            // Fetch appointments after users are fetched
            await fetchData(usersData);
        } catch (error) {
            console.error('Failed to fetch users and appointments:', error);
        }
    };
    
    const fetchData = async (usersData) => {
        await getProviderDetails(id);
    
        if (hpId) {
            fetchAppointmentsbyhpId(hpId, usersData.users); // Pass the healthcare provider ID and users data to the function
        } else {
            fetchAppointments(usersData.users);
        }
    };

    const getProviderDetails = async (userId) => {
        try {
            const response = await axios.get(`${BASE_URL}/providers/${userId}`);
            const data = response.data;

            if (data.length > 0) {
                const providerId = data[0].id; // Assuming the first provider is the correct one
                localStorage.setItem('provider_id', providerId);
                console.log('Provider ID set in local storage:', providerId);
            } else {
                console.error('Provider not found for user ID:', userId);
            }
        } catch (error) {
            console.error('Error fetching provider details:', error);
        }
    };

    const fetchAppointmentsbyhpId = async (hpId, usersData) => {
        try {
            if (!Array.isArray(usersData)) {
                console.error('Users data is not an array:', usersData);
                return;
            }
            const appointmentsResponse = await axios.get(`${BASE_URL}/appointments/hp/${hpId}`);
            // Map appointments to include user names
            let appointmentsWithNames = appointmentsResponse.data.map(appointment => {
                const user = usersData.find(user => user.id === appointment.user_id);
                return { ...appointment, user_name: user ? user.name : 'Unnamed Patient' };
            });
            appointmentsWithNames = appointmentsWithNames.map(appointment => {
              const date = new Date(`1970-01-01T${appointment.time}Z`);
                const hours = date.getUTCHours(); // Use getUTCHours() to get the hours in UTC
                const minutes = date.getMinutes();
                const ampm = hours >= 12 ? 'PM' : 'AM';
                const hours12 = hours % 12 || 12;

                const timeString = `${hours12}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
              
              return {
                ...appointment,
                time: timeString,
              };
              });
              setAppointments(appointmentsWithNames);
        } catch (error) {
            console.error('Failed to fetch appointments:', error);
        }
    };

    const fetchAppointments = async (usersData) => {
        try {
            const response = await axios.get(`${BASE_URL}/appointments/${id}`);
            setAppointments(response.data);
        } catch (error) {
            console.error('Failed to fetch appointments:', error);
        }
    };

    const handleReschedule = async (event) => {
        event.preventDefault();
        if (!selectedAppointment) return;
        const formData = new FormData(event.target);
        try {
            const updatedAppointment = {
                date: selectedDate.toISOString().split('T')[0],
                time: formData.get('time'),
                location: selectedLocation,
                status: 'Rescheduled'
            };
            const response = await axios.put(`${BASE_URL}/appointments/${selectedAppointment.id}`, updatedAppointment);
            const updatedAppointments = appointments.map(app => app.id === response.data.id ? response.data : app);
            setAppointments(updatedAppointments);
            closeRescheduleModal();
        } catch (error) {
            console.error('Error rescheduling appointment:', error);
        }
    };

    const handleConfirmCancel = async () => {
        if (!selectedAppointment) return;
        try {
            await axios.delete(`${BASE_URL}/appointments/${selectedAppointment.id}`);
            setAppointments(appointments.filter(app => app.id !== selectedAppointment.id));
            closeCancelModal();
        } catch (error) {
            console.error('Error cancelling appointment:', error);
        }
    };

    const handleCreateAppointment = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const user = users.find(user => user.id === Number(selectedUser));
        const user_name = user ? user.name : '';
        
        try {
            const newAppointment = {
                user_id: selectedUser,
                healthcare_provider_id: hpId,
                user_name: user_name,
                date: selectedDate.toISOString().split('T')[0],
                time: formData.get('time'),
                location: formData.get('location'),
                status: 'Scheduled'
            };
            const response = await axios.post(BASE_URL + '/appointments', newAppointment);
            setAppointments([...appointments, response.data]);
            closeCreateModal();
        } catch (error) {
            console.error('Error creating appointment:', error);
        }
    };

    const openCreateModal = () => setCreateModalIsOpen(true);
    const closeCreateModal = () => setCreateModalIsOpen(false);
    const closeRescheduleModal = () => setRescheduleModalIsOpen(false);
    const openRescheduleModal = (appointment) => {
        setSelectedAppointment(appointment);
        setRescheduleModalIsOpen(true);
    };
    const openCancelModal = (appointment) => {
        setSelectedAppointment(appointment);
        setCancelModalIsOpen(true);
    };
    const closeCancelModal = () => setCancelModalIsOpen(false);
    const handleAppointmentClick = (appointment) => {
        setSelectedAppointment(appointment);
      };
      const isToday = (date) => {
        const today = new Date();
        return (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        );
      };
    
      // Function to format the current time
      const formatTime = (time) => {
        const hours = time.getHours().toString().padStart(2, "0");
        const minutes = time.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
      };
    
      // Handle change in the selected date
      const handleDateChange = (date) => {
        setSelectedDate(date);
        // Update minTime based on whether the selected date is today
        if (isToday(date)) {
          const now = new Date();
          setMinTime(formatTime(now));
        } else {
          setMinTime("00:00"); // Reset minTime to default value
        }
      };
    return (
        <div
          className="appointments-component"
          style={{ position: "relative" }}
        >
          <button onClick={openCreateModal} className="button-create-schedule">
            <FontAwesomeIcon icon={faCalendarPlus} /> Schedule New Appointment
          </button>
          <Modal
            isOpen={createModalIsOpen}
            onRequestClose={closeCreateModal}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                border: "1px solid rgb(204, 204, 204)",
                background: "rgb(255, 255, 255)",
                overflow: "auto",
                borderRadius: "4px",
                outline: "none",
                padding: "20px",
                width: "40%",
                height: "auto",
              },
            }}
            contentLabel="Schedule New Appointment Modal"
          >
            <h2 style={{ marginBottom: "20px" }}>Schedule New Appointment</h2>
            <form
              onSubmit={handleCreateAppointment}
              style={{ display: "grid", gap: "20px" }}
            >
              {["User Name", "Date", "Time", "Location"].map(
                (labelText, index) => (
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <label>{labelText}:</label>
                    {labelText === "User Name" && (
                      <select
                        onChange={(e) => setSelectedUser(e.target.value)}
                        required
                        style={{
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                      >
                        <option value="">Select User</option>
                        {users.filter(user => user.role === 'Patient')
                        .map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    )}
                    {labelText === "Date" && (
                        <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy"
                        wrapperClassName="datePicker"
                      />
                      )}
                      {labelText === "Time" && (
                        <input
                        type="time"
                        name="time"
                        required
                        min={minTime} // Set the minimum time dynamically
                        style={{
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                        onChange={(e) => {
                          // Update minTime when the time input is touched
                          if (isToday(selectedDate)) {
                            const now = new Date();
                            setMinTime(formatTime(now));
                          }
                        }}
                      />
                      )}
                    {labelText === "Location" && (
                      <input
                        type="text"
                        name="location"
                        required
                        style={{
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                      />
                    )}
                  </div>
                )
              )}
              <div
                className="modal-buttons"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button type="button" onClick={closeCreateModal}>
                  <FontAwesomeIcon icon={faTimes} /> Cancel
                </button>
                <button type="submit">
                  <FontAwesomeIcon icon={faCalendarAlt} /> Schedule
                </button>
              </div>
            </form>
          </Modal>
          <h2>Appointments</h2>
          {appointments.length > 0 ? (
            <div className="prescriptions-grid">
              {appointments.map((appointment) => (
                <div
                  key={appointment.id}
                  className="appointment-card-hcp"
                  onClick={() => handleAppointmentClick(appointment)}
                >
                  <h3>{`Appointment with ${appointment.user_name || 'Patient'}`}</h3>
                  <p>
                    <strong>Date:</strong> {appointment.date}
                  </p>
                  <p>
                    <strong>Time:</strong> {appointment.time}
                  </p>
                  <p>
                    <strong>Location:</strong> {appointment.location}
                  </p>
                  <div className="appointment-actions">
                    <button
                      className="button-reschedule"
                      onClick={() => openRescheduleModal(appointment)}
                    >
                      <FontAwesomeIcon icon={faCalendarAlt} /> Reschedule
                    </button>
                    <button
                      className="button-cancel"
                      onClick={() => openCancelModal(appointment)}
                    >
                      <FontAwesomeIcon icon={faTimes} /> Cancel
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No upcoming appointments.</p>
          )}


        {/* Reschedule Modal */}
        <Modal
          isOpen={rescheduleModalIsOpen}
          onRequestClose={closeRescheduleModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              border: "1px solid rgb(204, 204, 204)",
              background: "rgb(255, 255, 255)",
              overflow: "auto",
              borderRadius: "4px",
              outline: "none",
              padding: "20px",
              width: "30%",
              height: "auto",
            },
          }}
          contentLabel="Reschedule Appointment Modal"
        >
          <h2>Reschedule Appointment</h2>
          <form
            onSubmit={handleReschedule}
            style={{ display: "grid", gap: "20px" }}
          >
            {["Date", "Time", "Location"].map((labelText, index) => (
              <div
                key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label>{labelText}:</label>
                {labelText === "Date" && (
                        <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy"
                        wrapperClassName="datePicker"
                      />
                      )}
                      {labelText === "Time" && (
                        <input
                        type="time"
                        name="time"
                        required
                        min={minTime} // Set the minimum time dynamically
                        style={{
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                        onChange={(e) => {
                          // Update minTime when the time input is touched
                          if (isToday(selectedDate)) {
                            const now = new Date();
                            setMinTime(formatTime(now));
                          }
                        }}
                      />
)}            
                {labelText === "Location" && (
                  <input
                    type="text"
                    onChange={(e) => setSelectedLocation(e.target.value)}
                     required
                    style={{
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                )}
              </div>
            ))}
            <div
              className="modal-buttons"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
               <button type="button" onClick={closeRescheduleModal}>
               <FontAwesomeIcon icon={faTimes} />Cancel
                </button>
                <button type="submit">
                <FontAwesomeIcon icon={faCalendarAlt} /> Reschedule
                </button>
            </div>
          </form>
        </Modal>

        {/* Cancel Modal */}
        <Modal
          isOpen={cancelModalIsOpen}
          onRequestClose={closeCancelModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              border: "1px solid rgb(204, 204, 204)",
              background: "rgb(255, 255, 255)",
              overflow: "auto",
              borderRadius: "4px",
              outline: "none",
              padding: "20px",
              width: "30%",
              height: "auto",
            },
          }}
          contentLabel="Cancel Appointment Modal"
        >
          <h2>Confirm Appointment Cancellation</h2>
          <div className="modal-buttons">
            <button onClick={closeCancelModal}>
              <FontAwesomeIcon icon={faTimes} /> No
            </button>
            <button onClick={handleConfirmCancel}>Yes, Cancel It</button>
          </div>
        </Modal>
      </div>
    );
}
export default AppointmentsComponent;