import React, { useState, useEffect } from 'react';
import '../../styles/DashboardHealthCareAdministrator.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { BASE_URL } from '../../utils'; // Ensure BASE_URL is correctly defined in your utilities

const ComplianceOversightComponent = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [complianceRequirements, setComplianceRequirements] = useState([]);
  const [selectedCompliance, setSelectedCompliance] = useState({ requirement: '', status: '' });
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    fetchComplianceRequirements();
  }, []);

  const fetchComplianceRequirements = async () => {
    const response = await fetch(`${BASE_URL}/compliances`);
    const data = await response.json();
    setComplianceRequirements(data);
  };

  const openModal = (compliance = null) => {
    setSelectedCompliance(compliance || { requirement: '', status: '' });
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedCompliance({ requirement: '', status: '' });
    setIsTouched(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIsTouched(true);
    setSelectedCompliance(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const saveCompliance = async () => {
    const method = selectedCompliance.id ? 'PUT' : 'POST';
    const url = selectedCompliance.id ? `${BASE_URL}/compliances/${selectedCompliance.id}` : `${BASE_URL}/compliances`;

    const response = await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(selectedCompliance)
    });

    if (response.ok) {
      fetchComplianceRequirements();
    }
    closeModal();
  };

  const updateComplianceStatus = async (id) => {
    const compliance = complianceRequirements.find(c => c.id === id);
    const updatedCompliance = { ...compliance, status: compliance.status === 'Compliant' ? 'Pending Review' : 'Compliant' };

    const response = await fetch(`${BASE_URL}/compliances/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedCompliance)
    });

    if (response.ok) {
      fetchComplianceRequirements();
    }
  };

  return (
    <div className="compliance-oversight">
      <h2>Compliance Oversight</h2>
      <button onClick={() => openModal()} className="add-compliance-btn">Add New Compliance Requirement</button>
      <Modal
        isOpen={modalIsOpen}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            border: "1px solid rgb(204, 204, 204)",
            background: "rgb(255, 255, 255)",
            overflow: "auto",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
            width: "30%",
            height: "auto",
          },
        }}
        onRequestClose={closeModal}
        contentLabel="Add/Edit Compliance Modal"
      >
        <h2>{selectedCompliance.id ? 'Edit Compliance' : 'Add New Compliance'}</h2>
        <form onSubmit={(e) => { e.preventDefault(); saveCompliance(); }}>
          <label>
            Compliance Name:
            <input type="text" name="name" required defaultValue={selectedCompliance.name} onChange={handleInputChange} />
          </label>
          <label>
            Status:
            <select name="status" value={selectedCompliance.status} onChange={handleInputChange}>
              <option value="Compliant">Compliant</option>
              <option value="Pending Review">Pending Review</option>
              <option value="Non-Compliant">Non-Compliant</option>
            </select>
          </label>
          <div className="modal-buttons">
            <button type="button" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </button>
            <button type="submit" disabled={!isTouched}>
              <FontAwesomeIcon icon={faSave} /> Save Changes
            </button>
          </div>
        </form>
      </Modal>

      <ul className="compliance-list">
        {complianceRequirements.map(requirement => (
          <li key={requirement.id} className="compliance-item" style={{ textAlign: "left" }}>
            <div className="compliance-details">
              <h3>{requirement.name}</h3>
              <p>Status: {requirement.status}</p>
            </div>
            <div className="compliance-actions">
              <button className="edit-btn" onClick={() => openModal(requirement)}>Edit</button>
              <button className="update-status-btn" onClick={() => updateComplianceStatus(requirement.id)}>
                {requirement.status === 'Compliant' ? 'Mark Non-Compliant' : 'Mark Compliant'}
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ComplianceOversightComponent;
