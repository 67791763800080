import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Messages.css';

const MessagesPage = () => {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const userId = localStorage.getItem('id');
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await axios.get('https://sxp9834.uta.cloud/users');
                const filteredContacts = response.data.filter(user => user.id !== userId);
                setContacts(filteredContacts);
            } catch (error) {
                console.error('Failed to fetch contacts:', error);
            }
        };
        fetchContacts();
    }, []);

    const selectContact = async (contact) => {
        setSelectedContact(contact);
        try {
            const response = await axios.post('https://sxp9834.uta.cloud/fetch-messages', {
                senderId: userId,
                receiverId: contact.id
            });
            setMessages(response.data);
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setTouched(true);
        setCurrentMessage(value);
    };

    const sendMessage = async () => {
        if (selectedContact && currentMessage.trim() !== '') {
            try {
                const response = await axios.post('https://sxp9834.uta.cloud/send-message', {
                    senderId: userId,
                    receiverId: selectedContact.id,
                    messageText: currentMessage
                });
                setMessages(response.data);
                setCurrentMessage('');
                setTouched(false);
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    return (
        <div className="messages-page">
            <div className="contacts">
                <h2>Contacts</h2>
                <div>
                    {contacts.map(contact => (
                        <div className={`contact ${selectedContact && selectedContact.id === contact.id ? 'selected' : ''}`} 
                         key={contact.id} onClick={() => selectContact(contact)}>
                            {contact.name || contact.email}
                        </div>
                    ))}
                </div>
            </div>
            <div className="chat">
                <h2>Chat</h2>
                <div className={messages.length > 0 ? "messages" : ""}>
                    {messages.map((msg, index) => (
                        <div key={index} className={`message ${Number(msg.sender_id) === Number(userId) ? 'sent' : 'received'}`}>{msg.message_text}</div>
                    ))}
                </div>
                <div className="input-area">
                    <input
                        type="text"
                        required
                        value={currentMessage}
                        onChange={handleInputChange}
                        placeholder="Type a message..."
                    />
                    <button type="submit" style={{background: "#005a8d"}} disabled={!touched} onClick={sendMessage}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default MessagesPage;