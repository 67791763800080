import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import '../../src/styles/ResetPassword.css';
import {BASE_URL} from '../../src/utils';


function ResetPassword() {
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/resetpassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();
            if (response.ok) {
                alert('Password has been updated successfully. Please use the updated password to login.');
                navigate('/login');
            } else {
                throw new Error(data.message || 'Failed to reset password');
            }
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="reset-password-form">
            <h2>Reset Password</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <label>
                    New Password
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </label>
                <label>
                    Confirm New Password
                    <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                </label>
                <button type="submit">Reset Password</button>
            </form>
        </div>
    );
}

export default ResetPassword;
