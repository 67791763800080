import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../utils';

const UserManagementComponent = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const role = 'Patient';

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/users/role/${role}`);
                setUsers(response.data.map(user => ({
                    ...user,
                    isActive: true
                })));
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const toggleUserStatus = (id) => {
        const userConfirmation = window.confirm("This will delete the user. Are you sure?");
        if (userConfirmation) {
            deleteUser(id);
        }

    };

    const deleteUser = async (id) => {
        try {
            await axios.delete(`${BASE_URL}/users/${id}`);
            setUsers(users.filter(user => user.id !== id));
            alert('User deleted successfully');
        } catch (err) {
            alert(`Failed to delete user: ${err.message}`);
        }
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="user-management">
            <h2>User Management</h2>
            <table className="user-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.role}</td>
                            <td>{user.isActive ? 'Active' : 'Inactive'}</td>
                            <td>
                                <button
                                    className={user.isActive ? 'deactivate-btn' : 'activate-btn'}
                                    onClick={() => toggleUserStatus(user.id, user.isActive)}
                                >
                                    {user.isActive ? 'Deactivate' : 'Activate'}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserManagementComponent;