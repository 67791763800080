import Modal from 'react-modal';
import '../../styles/DashboardUser.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../utils';

const HCPEPrescriptionsComponent = () => {
    const [prescriptions, setPrescriptions] = useState([]);
    const [users, setUsers] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [currentPrescription, setCurrentPrescription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updatedPrescription, setUpdatedPrescription] = useState({
        medication_name: '',
        dosage: '',
        frequency: '',
        start_date: '',
        end_date: '',
        refills_remaining: 0
    });
    const [selectedUser, setSelectedUser] = useState(''); // Define selectedUser state
    const [selectedPharmacist, setSelectedPharmacist] = useState(''); // Define selectedPharmacist state
    const [medicationName, setMedicationName] = useState('');
    const [dosage, setDosage] = useState('');
    const [frequency, setFrequency] = useState('');
    const [refillsRemaining, setRefillsRemaining] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');


    const id = localStorage.getItem('id');
    const hpId = localStorage.getItem('provider_id');

    useEffect(() => {
        getProviderDetails(id);
        if (hpId) {
            fetchPrescriptionsbyhpId(hpId);
        } else {
            fetchPrescriptions();
        }
    }, []);
    const getProviderDetails = async (id) => {
        try {
            const response = await axios.get(`${BASE_URL}/providers/${id}`);
            const data = response.data;

            if (data.length > 0) {
                const providerId = data[0].id; // Assuming the first provider is the correct one
                localStorage.setItem('provider_id', providerId);
                console.log('Provider ID set in local storage:', providerId);
            } else {
                console.error('Provider not found for user ID:', id);
            }
        } catch (error) {
            console.error('Error fetching provider details:', error);
        }
    };

    const fetchPrescriptionsbyhpId = async () => {
        try {
            const usersResp = await axios.get(`${BASE_URL}/users`);
            setUsers(usersResp.data.users);
            const prescriptionsResp = await axios.get(`${BASE_URL}/prescriptions/hp/${hpId}`);
            const enrichedPrescriptions = prescriptionsResp.data.map(prescription => {
                const user = usersResp.data.users.find(user => user.email === prescription.user_email);
                return { ...prescription, patientName: user ? user.name : 'Unnamed Patient' };
            });
            setPrescriptions(enrichedPrescriptions);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchPrescriptions = async () => {
        try {
            const usersResp = await axios.get(`${BASE_URL}/users`);
            setUsers(usersResp.data.users);
            const prescriptionsResp = await axios.get(`${BASE_URL}/prescriptions`);
            const enrichedPrescriptions = prescriptionsResp.data.map(prescription => {
                const user = usersResp.data.users.find(user => user.email === prescription.user_email);
                return { ...prescription, patientName: user ? user.name : 'Unnamed Patient' };
            });
            setPrescriptions(enrichedPrescriptions);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };
    
    const handleCreatePrescription = async (event) => {
        event.preventDefault();
        try {
            const newPrescription = {
                user_email: selectedUser,
                pharmacist_id: selectedPharmacist,
                healthcare_provider_id: hpId,
                medication_name: medicationName,
                dosage: dosage,
                frequency: frequency,
                start_date: startDate,
                end_date: endDate,
                refills_remaining: refillsRemaining
            };
    
            const response = await axios.post(BASE_URL + '/prescriptions', newPrescription);
            const prescription = response.data; 
            const user = users.find(user => user.email === prescription.user_email);
            const enrichedPrescription = {
                ...prescription,
                patientName: user ? user.name : 'Unnamed Patient'
            };
            setPrescriptions([...prescriptions, enrichedPrescription]);
            closeCreateModal();
        } catch (error) {
            console.error('Error creating prescription:', error);
        }
    };
    const formatDate = (date) => {
        date = new Date(date);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
    
        // Add leading zeros if month or day is less than 10
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }
    
        return `${year}-${month}-${day}`;
    };
    
    

    const handleOpenModal = (prescription) => {
        setCurrentPrescription(prescription);
        setUpdatedPrescription({ ...prescription });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const openCreateModal = () => setCreateModalIsOpen(true);
    const closeCreateModal = () => setCreateModalIsOpen(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedPrescription({ ...updatedPrescription, [name]: value });
    };

    const handleSaveChanges = async (event) => {
        event.preventDefault();
        try {
            await axios.put(`${BASE_URL}/prescriptions/${currentPrescription.id}`, updatedPrescription);
            const updatedPrescriptions = prescriptions.map(p => (p.id === currentPrescription.id ? updatedPrescription : p));
            setPrescriptions(updatedPrescriptions);
            handleCloseModal();
        } catch (error) {
            console.error('Error updating prescription:', error);
        }
    };
    const handleDeletePrescription = async (prescriptionId) => {
                try {
                    await axios.delete(`${BASE_URL}/prescriptions/${prescriptionId}`);
                    setPrescriptions(prev => prev.filter(p => p.id !== prescriptionId));
                } catch (error) {
                    console.error('Error deleting prescription:', error);
                }
            };

    return (
        <div className="prescriptions-container"style={{ position: "relative" }}
        >   
            <h2>E-Prescriptions</h2>
            <button onClick={openCreateModal} className="button-create-prescription">
                Create New Prescription</button>
            {loading ? (
                <p>Loading...</p>
            ) : prescriptions.length > 0 ? (
                <div className="prescriptions-grid">
                    {prescriptions.map((prescription) => (
                        <div key={prescription.id} className="prescription-card">
                            <h3 className="medication-name">{prescription.patientName}</h3>
                            <div className="prescription-info">
                                <p><strong>Medication:</strong> {prescription.medication_name}</p>
                                <p><strong>Dosage:</strong> {prescription.dosage}</p>
                                <p><strong>Frequency:</strong> {prescription.frequency}</p>
                                <p><strong>Start Date:</strong> {prescription.start_date}</p>
                                <p><strong>End Date:</strong> {prescription.end_date}</p>
                            </div>
                            <div className="prescription-actions">
                                <button className="button-view" onClick={() => handleOpenModal(prescription)}>Edit Prescription</button>
                                <button className="button-view" onClick={() => handleDeletePrescription(prescription.id)}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No e-prescriptions found.</p>
            )}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        color: 'lightsteelblue',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        height: '60%',
                    },
                }}
                contentLabel="Prescription Details"
            >
                {currentPrescription && (
                <form onSubmit={handleSaveChanges}>
                    <h2>{currentPrescription.medicationName}</h2>
                    <div className="form-group">
                    <label htmlFor="medication_name">Medication Name</label>
                    <input type="text" id="medication_name" name="medication_name" required value={updatedPrescription.medication_name} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                    <label htmlFor="dosage">Dosage</label>
                    <input type="text" id="dosage" name="dosage" required value={updatedPrescription.dosage} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                    <label htmlFor="frequency">Frequency</label>
                    <input type="text" id="frequency" name="frequency" required value={updatedPrescription.frequency} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                    <label htmlFor="start_date">Start Date</label>
                    <input 
                        type="date" 
                        id="start_date" 
                        name="start_date" 
                        required 
                        value={updatedPrescription.start_date} 
                        min={formatDate(new Date())}
                        onChange={handleInputChange} 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="end_date">End Date</label>
                    <input 
                        type="date" 
                        id="end_date" 
                        name="end_date" 
                        required 
                        value={updatedPrescription.end_date} 
                        min={updatedPrescription.start_date ? formatDate(new Date(new Date(updatedPrescription.start_date).getTime() + 86400000)) : formatDate(new Date())}
                        onChange={handleInputChange} 
                    />
                </div>

                    <div className="appointment-actions">
                    <button type="submit" className="button-reschedule">Save Changes</button>
                    <button className="button-cancel" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </form>
                )}

            </Modal>
            <Modal
                isOpen={createModalIsOpen}
                onRequestClose={closeCreateModal}
                style={{
                    overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                    content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    border: "1px solid rgb(204, 204, 204)",
                    background: "rgb(255, 255, 255)",
                    overflow: "auto",
                    borderRadius: "4px",
                    outline: "none",
                    padding: "20px",
                    width: "40%",
                    height: "auto",
                    },
                }}
                contentLabel="Create New Prescription Modal"
                >
                <h2 style={{ marginBottom: "20px" }}>Create New Prescription</h2>
                <form onSubmit={handleCreatePrescription} style={{ display: "grid", gap: "20px" }}>
                    <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        alignItems: "center",
                        gap: "10px",
                    }}
                    >
                    <label>Select User:</label>
                    <select
                        onChange={(e) => setSelectedUser(e.target.value)}
                        required
                        style={{
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        }}
                    >
                        <option value="">Select User</option>
                        {users.filter(user => user.role === 'Patient')
                        .map((user) => (
                        <option key={user.email} value={user.email}>
                            {user.name}
                        </option>
                        ))}
                    </select>
                    </div>
                    <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        alignItems: "center",
                        gap: "10px",
                    }}
                    >
                    <label>Select Pharmacist:</label>
                    <select
                        onChange={(e) => setSelectedPharmacist(e.target.value)}
                        required
                        style={{
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        }}
                    >
                        <option value="">Select Pharmacist</option>
                        {users.filter(user => user.role === 'Pharmacist')
                        .map((pharmacist) => (
                        <option key={pharmacist.id} value={pharmacist.id}>
                            {pharmacist.name}
                        </option>
                        ))}
                    </select>
                    </div>
                    {["Medication Name", "Dosage", "Frequency", "Refills Remaining", "Start Date", "End Date"].map((labelText, index) => (
                    <div
                        key={index}
                        style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        alignItems: "center",
                        gap: "10px",
                        }}
                    >
                        <label>{labelText}:</label>
                        {labelText === "Medication Name" && (
                        <input
                            type="text"
                            name="medication_name"
                            value={medicationName}
                            onChange={(e) => setMedicationName(e.target.value)}
                            required
                            style={{
                            padding: "10px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            }}
                        />
                        )}
                        {labelText === "Dosage" && (
                        <input
                            type="text"
                            name="dosage"
                            value={dosage}
                            onChange={(e) => setDosage(e.target.value)}
                            required
                            style={{
                            padding: "10px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            }}
                        />
                        )}
                        {labelText === "Frequency" && (
                        <input
                            type="text"
                            name="frequency"
                            value={frequency}
                            onChange={(e) => setFrequency(e.target.value)}
                            required
                            style={{
                            padding: "10px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            }}
                        />
                        )}
                        {labelText === "Refills Remaining" && (
                        <input
                            type="number"
                            name="refills_remaining"
                            value={refillsRemaining}
                            min={0}
                            onChange={(e) => setRefillsRemaining(parseInt(e.target.value))}
                            required
                            style={{
                                padding: "10px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                            }}
                        />
                        )}
                        {labelText === "Start Date" && (
                            <input
                                type="date"
                                name="start_date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                required
                                min={formatDate(new Date())} // Set min to current date
                                style={{
                                    padding: "10px",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                }}
                            />
                        )}
                        {labelText === "End Date" && (
                            <input
                                type="date"
                                name="end_date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                required
                                min={startDate ? formatDate(new Date(new Date(startDate).getTime() + 86400000)) : formatDate(new Date())}
                                style={{
                                    padding: "10px",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                }}
                            />
                        )}

                    </div>
                    ))}
                    <div
                    className="modal-buttons"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                    }}
                    >
                    <div className="modal-buttons">
                            <button onClick={closeCreateModal}>Cancel</button>
                            <button> Prescribe</button>
                        </div>
                        
                    </div>
                </form>
                </Modal>
        </div>
    );
};

export default HCPEPrescriptionsComponent;

