import React, { useState, useEffect } from 'react';
import '../styles/MedicationDispensationComponent.css';
import { BASE_URL } from '../utils';
import axios from 'axios';

const MedicationDispensationComponent = () => {
    const [medications, setMedications] = useState([]);

    const getUsers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/users`);
            console.log(response.data.users);
            const users = response.data.users;
            return users;
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
    };
    
    const getCurrentUser = async (currentEmail) => {
        const users = await getUsers();
        const currentUser = users.filter(user => user.email === currentEmail);
        return currentUser.length > 0 ? currentUser[0].name : null;
    };

    useEffect(() => {
        const userId = localStorage.getItem('id');
        fetch(`${BASE_URL}/prescriptions`)
            .then(response => response.json())
            .then(async data => {
                const providersResponse = await axios.get(`${BASE_URL}/providers`);
                const fetchedProviders = providersResponse.data;
                const filteredData = data.filter(prescription => prescription.pharmacist_id === parseInt(userId));
                const medicationsWithUserNames = await Promise.all(
                    filteredData.map(async prescription => {
                        const response = await getCurrentUser(prescription.user_email);
                        return { ...prescription, userName: response };
                    })
                );
                let prescriptionsWithNames = medicationsWithUserNames.map(prescription => {
                    const provider = fetchedProviders.find(provider => provider.id === prescription.healthcare_provider_id);
                    return { ...prescription, provider_name: provider? provider.provider_name : 'Unnamed provider' };
                });
                setMedications(prescriptionsWithNames);
                console.log(prescriptionsWithNames);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    const updateStatus = (id, newStatus) => {
        fetch(`${BASE_URL}/prescriptions/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ dispense: newStatus })
        })
            .then(response => {
                if (response.ok) {
                    setMedications(medications.map(med =>
                        med.id === id ? { ...med, dispense: newStatus } : med
                    ));
                } else {
                    console.error('Failed to update status');
                }
            })
            .catch(error => console.error('Error updating status:', error));
    };

    return (
      <div className="medication-dispensation-container">
        <h2>Medication Dispensation</h2>
        <div className='prescriptions-grid'>
            {medications.map((medication) => (
                <div key={medication.id} className="prescription-card">
                    <h3 className="medication-name">Prescription Name: {medication.medication_name}</h3>
                    <div className="prescription-info">
                        <p><strong>Patient Name:</strong> {medication.userName}</p>
                        <p><strong>Provider Name:</strong> {medication.provider_name}</p>
                        <p><strong>Medication:</strong> {medication.medication_name}</p>
                        <p><strong>Dosage:</strong>  {medication.dosage} - {medication.frequency}</p>
                        <p><strong>StartDate:</strong> {medication.start_date}</p>
                        <p><strong>EndDate:</strong> {medication.end_date}</p>
                        <p><strong>Status: {medication.dispense}</strong></p>
                    </div>
                    <div className="prescription-actions">
                        {medication.dispense === "Not Dispensed" && (
                            <button onClick={() => updateStatus(medication.id, "Dispensed")}>
                                Dispense
                            </button>
                        )}
                    </div>
                </div>
            ))}
        </div>
    
        </div>
    );


};

export default MedicationDispensationComponent;
