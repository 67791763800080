import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTimes, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../../styles/DashboardUser.css';
import { BASE_URL } from '../../utils';

const PersonalHealthRecordsComponent = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [healthRecords, setHealthRecords] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newRecord, setNewRecord] = useState({
    "medical_history": "",
    "prescriptions": null,
    "appointments": null,
    "allergies": "",
    "immunizations": "",
    "vital_signs": "",
    "lab_results": null,
    "surgeries": "",
    "family_history": "",
    "blood_group": "",
    "lifestyle": "",
    "emergency_contact": "",
    "conditions": "",
    "user_id": ""
}); 
const [isSubmitted, setIsSubmitted] = useState(false);
const [isValid, setIsValid] = useState(false);
const user_id = localStorage.getItem('id');

useEffect(() => {
  if (isSubmitted) {
    setIsValid(Object.values(newRecord).every(value => typeof value === 'string' && value.trim() !== ''));
  }
}, [newRecord, isSubmitted]);

  useEffect(() => {
    fetchHealthRecords();
  }, []);

  const fetchHealthRecords = async () => {
    setIsLoading(true);
    const id = localStorage.getItem('id');
    try {
      const response = await axios.get(`${BASE_URL}/medical-records/${id}`);
      const data = response.data[0];
      console.log('data:', data )
      setHealthRecords(data);
      console.log('healthRecords:', healthRecords)
      setEditedRecord({ ...data });
    } catch (error) {
      console.error('Error fetching health records:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddInputChange = (event) => {
    setNewRecord({ ...newRecord, [event.target.name]: event.target.value });
  };
  const handleAddSection = async (event) => {
    console.log('newRecord:', newRecord); 
    event.preventDefault();
    setIsSubmitted(true);
    if (isValid) {
      try {
        const response = await axios.post(`${BASE_URL}/medical-records`, {
          user_id: user_id, 
          ...newRecord
        });
        if (response.status === 201) {
          console.log('Successfully Created medical records');
          setHealthRecords(newRecord);
          fetchHealthRecords();
        } else {
          console.error('Failed to Create medical records:', response);
        }
      } catch (error) {
        console.error('An error occurred while Creating medical records:', error);
      }
      closeModal();
    }
  };

  const handleInputChange = (e) => {
    setIsTouched(true);
    const { name, value } = e.target;
    setEditedRecord(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEditSection = async (event) => {
    event.preventDefault();
    const id = healthRecords.id;
    try {
      const response = await axios.put(`${BASE_URL}/medical-records/${id}`, editedRecord);
      if (response.status === 200) {
        console.log('Successfully updated medical records');
        setHealthRecords(editedRecord);
      } else {
        console.error('Failed to update medical records:', response);
      }
    } catch (error) {
      console.error('An error occurred while updating medical records:', error);
    }
    setEditModalIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
    setNewRecord({
      "medical_history": "",
      "prescriptions": null,
      "appointments": null,
      "allergies": "",
      "immunizations": "",
      "vital_signs": "",
      "lab_results": null,
      "surgeries": "",
      "family_history": "",
      "blood_group": "",
      "lifestyle": "",
      "emergency_contact": "",
      "conditions": ""
    });
    setIsSubmitted(false);
  }
  const closeModal = () => setIsOpen(false);
  const openEditModal = () => setEditModalIsOpen(true);
  const closeEditModal = () => setEditModalIsOpen(false);

  if (isLoading) {
    return <div><FontAwesomeIcon icon={faSpinner} spin />Loading...</div>;
  }

  return (
    <div
      className="personal-health-records-component"
      style={{ position: "relative" }}
    >
      <h2>Personal Health Records</h2>
      {healthRecords === undefined && (
        <button onClick={openModal} className="button-add-record">
          <FontAwesomeIcon icon={faPlus} /> Add
        </button>
      )}

      <Modal
        isOpen={modalIsOpen}
        className="modal-edit-content"
        overlayClassName="modal-edit-overlay"
        onRequestClose={closeModal}
        contentLabel="Add Record Modal"
      >
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
            color: "#005a8d",
          }}
        >
          Add Health Records
        </h2>
        <form
          className="modal-form"
          onChange={handleAddInputChange}
          onSubmit={handleAddSection}
        >
          {Object.keys(newRecord).map((key, index) => {
            if (key === "user_id") return null;
            return (
              <div key={index} className="health-record-section">
                <h3>{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                <input
                  type={typeof newRecord[key] === "number" ? "number" : "text"}
                  name={key}
                  value={newRecord[key] || ""}
                  onChange={handleAddInputChange}
                />
                {isSubmitted && !newRecord[key] && (
                  <p style={{ color: "red" }}>This field is required.</p>
                )}
              </div>
            );
          })}

          <div className="modal-buttons">
            <button onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </button>
            <button type="submit">
              <FontAwesomeIcon icon={faSave} /> Save Changes
            </button>
          </div>
        </form>
      </Modal>
      {/* {healthRecords !== undefined && (
      <button onClick={openEditModal} className="edit-record-button">
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      )} */}
      <Modal
        isOpen={editModalIsOpen}
        className="modal-edit-content"
        overlayClassName="modal-edit-overlay"
        onRequestClose={closeEditModal}
        contentLabel="Edit Record Modal"
      >
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
            color: "#005a8d",
          }}
        >
          Edit Health Records
        </h2>
        <form className="modal-form" onChange={handleInputChange}>
        {healthRecords && Object.keys(healthRecords).length > 0 ? (
          Object.keys(editedRecord).map((key, index) => {
            if (
              key === "id" ||
              key === "user_id" ||
              key === "created_at" ||
              key === "updated_at"
            )
              return null;
            return (
              <div key={index} className="health-record-section">
                <h3>{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                <input
                  type={
                    typeof editedRecord[key] === "number" ? "number" : "text"
                  }
                  name={key}
                  defaultValue={editedRecord[key]}
                  onChange={handleInputChange}
                />
              </div>
            );
          })
        ) : (
          <p>No health records found.</p>
        )}

        </form>
        <div className="modal-buttons">
          <button onClick={closeEditModal}>
            <FontAwesomeIcon icon={faTimes} /> Cancel
          </button>
          <button disabled={!isTouched} onClick={handleEditSection}>
            <FontAwesomeIcon icon={faSave} /> Save Changes
          </button>
        </div>
      </Modal>
      <div className="health-info">
        {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}

        {healthRecords && Object.keys(healthRecords).length > 0 ? (
          Object.keys(healthRecords).map((key, index) => {
            if (
              key === "id" ||
              key === "user_id" ||
              key === "created_at" ||
              key === "updated_at" ||
              !healthRecords[key]
            )
              return null;
            let value = healthRecords[key];
            let height, weight;
            if (key === "vital_signs") {
              const vitalSigns = value.split(",");
              height = vitalSigns[0];
              weight = vitalSigns[1];
            }
            return (
              <div key={index}>
                {key === "vital_signs" ? (
                  <>
                    <h3>{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                    <ul>
                      <li>Height: {height}</li>
                      <li>Weight: {weight}</li>
                    </ul>
                  </>
                ) : (
                  <>
                    <h3>{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                    <ul>
                      <li>{healthRecords[key]}</li>
                    </ul>
                  </>
                )}
              </div>
            );
          })
        ) : (
          // Handle the case where healthRecords is undefined or null.
          <p>No health records found.</p>
        )}
      </div>
      {/* <p>{healthRecords[key]}</p> */}
    </div>
  );
        };

export default PersonalHealthRecordsComponent;
