import React from 'react';
import '../styles/HomePage.css';
import Lottie from 'react-lottie';

// import animationData1 from '../lottie/health1.json';
import animationData2 from '../lottie/schedule.json';
import animationData3 from '../lottie/medication.json';
import animationData1 from '../lottie/health.json';


const defaultOptions = (animationData) => ({
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
    }
});

const Section = ({ animationData, title, content, reverse }) => {
    const lottie = <Lottie options={defaultOptions(animationData)} height={300} width={500} />;
    const textContent = (
        <div className="home-text">
            <h2>{title}</h2>
            <p>{content}</p>
        </div>
    );

    return (
        <div className={`home-section ${reverse ? 'reverse' : ''}`}>
            {reverse ? textContent : lottie}
            {reverse ? lottie : textContent}
        </div>
    );
};

const HomePage = () => {
    return (
        <div className="home-container">
            <header className="home-header">
                <h1>Welcome to SmartHealth Hub</h1>
                <span>Your integrated healthcare platform connecting you to a healthier future.</span>
            </header>
            <main>
                <Section
                    animationData={animationData1}
                    title="Manage Personal Health Records"
                    content="SmartHealth Hub's Personal Health Records feature offers seamless management of your medical
                     information. Say goodbye to scattered paperwork and hello to organized, up-to-date health data. Whether
                      you need to review your medical history, share information with your healthcare provider, or track your
                       progress, our PHR feature simplifies the process. Take control of your health journey confidently with
                        SmartHealth Hub's Personal Health Records."
                />
                <Section
                    animationData={animationData2}
                    title="Schedule Appointments Easily"
                    content="SmartHealth Hub simplifies appointment scheduling. With just a few clicks, book and manage
                     your appointments hassle-free. Enjoy real-time availability and instant confirmation, all tailored
                      to fit your schedule. Take control of your healthcare journey effortlessly with SmartHealth Hub."
                    reverse
                />
                <Section
                    animationData={animationData3}
                    title="Access to Medication Management"
                    content="With SmartHealth Hub's medication management feature, staying on top of your prescriptions
                     is a breeze. Receive gentle reminders and effortlessly track your medications, ensuring you never 
                     miss a dose. Take charge of your health with ease and confidence, all from one convenient platform."
                />
                {/* Add more sections as needed */}
            </main>

        </div>
    );
};

export default HomePage;
