import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../../styles/DashboardUser.css';
import { BASE_URL } from '../../utils';

Modal.setAppElement('#root');

const EPrescriptionsComponent = ({ id }) => {
    const [prescriptions, setPrescriptions] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentPrescription, setCurrentPrescription] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchPrescriptions();
    }, []);

    const fetchPrescriptions = async () => {
        try {
            setIsLoading(true);
            const id = localStorage.getItem('id');
            const email = localStorage.getItem('userEmail');
            const patientName = localStorage.getItem('userName');
            const response = await axios.get(`${BASE_URL}/prescriptions/email/${email}`);
            response.data.forEach(prescription => {
                prescription.patientName = patientName;
            });
            setPrescriptions(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching prescriptions:', error);
        }
    };

    const handleOpenModal = (prescription) => {
        setCurrentPrescription(prescription);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div className="prescriptions-container">
            <h2>E-Prescriptions</h2>
            {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
            prescriptions.length > 0 ? (
                <div className="prescriptions-grid">
                    {prescriptions.map((prescription) => (
                        <div key={prescription.id} className="prescription-card">
                            <h3 className="medication-name">Prescription Name: {prescription.medication_name}</h3>
                            <div className="prescription-info">
                                <p><strong>Patient:</strong> {prescription.patientName}</p>
                                <p><strong>Medication:</strong> {prescription.medication_name}</p>
                                <p><strong>Dosage:</strong> {prescription.dosage}</p>
                                <p><strong>Frequency:</strong> {prescription.frequency}</p>
                                <p><strong>StartDate:</strong> {prescription.start_date}</p>
                                <p><strong>EndDate:</strong> {prescription.end_date}</p>
                            </div>
                            <div className="prescription-actions">
                                <button className="button-view" onClick={() => handleOpenModal(prescription)}>View Details</button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No e-prescriptions found.</p>
            ))}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        color: 'lightsteelblue',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        height: '52%',
                        paddingLeft: '40px',
                    },
                }}
            >
                
                {currentPrescription && (
                    <>
                        <h2 style={{ marginBottom: "20px", color: "#005a8d" }}>Prescription Details</h2>
                        <p><strong>Medication Name:</strong> {currentPrescription.medication_name}</p>
                        <p><strong>Patient:</strong> {currentPrescription.patientName}</p>
                        <p><strong>Medication:</strong> {currentPrescription.medication_name}</p>
                        <p><strong>Dosage:</strong> {currentPrescription.dosage}</p>
                        <p><strong>Frequency:</strong> {currentPrescription.frequency}</p>
                        {/* <p><strong>Day and Time to take:</strong> {currentPrescription.dayToTake} at {currentPrescription.timeToTake}</p> */}
                        <p><strong>Prescription Date:</strong> {currentPrescription.start_date}</p>
                        {/* <p><strong>Prescribing Doctor:</strong> {currentPrescription.prescribingDoctor}</p>
                        <p><strong>Pharmacy:</strong> {currentPrescription.pharmacyDetails.name}, {currentPrescription.pharmacyDetails.address}</p>
                        <p><strong>Instructions:</strong> {currentPrescription.instructions}</p>
                        <p><strong>Duration:</strong> {currentPrescription.duration}</p> */}
                        <p><strong>Refills:</strong> {currentPrescription.refills_remaining}</p>
                        {/* <p><strong>Warnings:</strong> {currentPrescription.warnings}</p> */}
                        <div className="modal-buttons">
                            <button onClick={handleCloseModal}><FontAwesomeIcon icon={faTimes} /> Close</button>
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default EPrescriptionsComponent;
