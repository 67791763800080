import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/DashboardUser.css';
import { useNavigate } from 'react-router-dom';
import PersonalHealthRecordsComponent from '../components/Patient/UserPersonalHealthRecordsComponent';
import PatientAppointmentsComponent from '../components/Patient/UserPatientAppointmentsComponent';
import SymptomCheckerComponent from '../components/Patient/UserSymptomCheckerComponent';
import EPrescriptionsManagementComponent from '../components/Patient/UserEPrescriptionsComponent';
import CommunityInteraction from '../components/CommunityInteraction';
import { BASE_URL} from '../utils';

const UserDashboard = () => {
    const [currentTab, setCurrentTab] = useState('healthRecords');
    const [reminders, setReminders] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';  
    
        if (!isLoggedIn) {
            navigate('/login');
        } else {
            fetchPrescriptions();  
        }
    }, [navigate]);

    // useEffect(() => {
        const fetchPrescriptions = async () => {
            try {
                const email = localStorage.getItem('userEmail');
                const response = await axios.get(`${BASE_URL}/prescriptions/email/${email}`);
                const filteredReminders = response.data.filter(prescription => {
                    const frequency = prescription.frequency.split(' ')[0];
                    if (frequency === '2') {
                        return true;
                    }
                    return false;
                }).map(prescription => ({
                    id: prescription.id,
                    medicationName: prescription.medication_name,
                    timeToTake: prescription.frequency.includes('morning') ? 'Morning' : 'Evening'
                }));
                setReminders(filteredReminders);
            } catch (error) {
                console.error('Failed to fetch prescriptions', error);
            }
        };

        // fetchPrescriptions();
    // }, []);

    const ReminderComponent = ({ reminders }) => (
        <div className="reminder">
          <h2>Medication Reminder</h2>
          <p>You have upcoming medication reminders. Here are the details:</p>
          {reminders.map((reminder, index) => (
            <p key={index}><strong>Medication Name:</strong> {reminder.medicationName} - <strong>Time to Take:</strong> {reminder.timeToTake}</p>
          ))}
          <p>Please make sure to take your medication on time.</p>
        </div>
      );
      

    return (
        <div className="patient-dashboard-container">
            <h1>User Dashboard</h1>
            {reminders.length > 0 && (
            <div className="reminder-alert">
                <ReminderComponent reminders={reminders} />
            </div>
            )}

            <nav className="patient-dashboard-nav">
                <button onClick={() => setCurrentTab('healthRecords')} className={currentTab === 'healthRecords' ? 'active' : ''}>Personal Health Records</button>
                <button onClick={() => setCurrentTab('appointments')} className={currentTab === 'appointments' ? 'active' : ''}>Appointment Management</button>
                <button onClick={() => setCurrentTab('symptomChecker')} className={currentTab === 'symptomChecker' ? 'active' : ''}>Symptom Checker</button>
                <button onClick={() => setCurrentTab('ePrescriptions')} className={currentTab === 'ePrescriptions' ? 'active' : ''}>Prescriptions</button>
                <button onClick={() => setCurrentTab('communityInteraction')} className={currentTab === 'communityInteraction' ? 'active' : ''}>Community Interaction</button>
            </nav>
            <main className="patient-dashboard-main">
                {currentTab === 'healthRecords' && <PersonalHealthRecordsComponent />}
                {currentTab === 'appointments' && <PatientAppointmentsComponent />}
                {currentTab === 'symptomChecker' && <SymptomCheckerComponent />}
                {currentTab === 'ePrescriptions' && <EPrescriptionsManagementComponent />}
                {currentTab === 'communityInteraction' && <CommunityInteraction />}
            </main>
        </div>
    );
};

export default UserDashboard;