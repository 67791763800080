import React from 'react';
import '../styles/FeaturesPage.css';
function FeaturesPage() {
    return (
        <div className="features-container">
            <h1 className="features-title">Features of SmartHealth Hub</h1>
            <div className="feature-list">
                <div className="feature-item">
                    <h2>General Features</h2>
                    <ul>
                        <li>Home Page: Introduction and overview of SmartHealth Hub.</li>
                        <li>Login/Logout: Secure login and logout functionality.</li>
                        <li>Registration: Sign-up forms for different user roles.</li>
                        <li>Contact Page: Contact form and information.</li>
                        <li>Forum Page: Platform for discussions and sharing information.</li>
                        <li>Health Articles: Repository of health-related articles and tips.</li>
                    </ul>
                </div>
                <div className="feature-item">
                    <h2>Patient-Specific Features</h2>
                    <ul>
                        <li>Dashboard: Manage personal health records and appointments.</li>
                        <li>Symptom Checker: Access to symptom checker and health analytics.</li>
                        <li>E-Prescriptions: Manage e-prescriptions and medication reminders.</li>
                    </ul>
                </div>
                <div className="feature-item">
                    <h2>Healthcare Provider Features</h2>
                    <ul>
                        <li>Dashboard: View and manage appointments and patient records.</li>
                        <li>E-Prescriptions: Send e-prescriptions to patients.</li>
                        <li>Secure Messaging: Communicate securely with patients.</li>
                        <li>Forum Contribution: Contribute to health forums.</li>
                    </ul>
                </div>
                <div className="feature-item">
                    <h2>Administrator Features</h2>
                    <ul>
                        <li>Dashboard: Manage users, system settings, and reporting tools.</li>
                        <li>User Management: Interface for managing patient and provider profiles.</li>
                        <li>System Configuration: Settings for system customization.</li>
                    </ul>
                </div>
                <div className="feature-item">
                    <h2>Pharmacist Features</h2>
                    <ul>
                        <li>Dashboard: Manage medication dispensation and history.</li>
                        <li>Secure Communication: Communicate with healthcare providers and patients.</li>
                    </ul>
                </div>
                <div className="feature-item">
                    <h2>Health Administrator Features</h2>
                    <ul>
                        <li>Dashboard: Manage facilities, staff, and compliance tracking.</li>
                        <li>Facility Management: Tools for coordinating staff and resources.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default FeaturesPage;
