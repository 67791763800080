import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

const IncidentResponseComponent = () => {
    const [incidents, setIncidents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newIncident, setNewIncident] = useState({
        patient_id: '',
        provider_id: '',
        type: '',
        description: '',
        actions_taken: '',
        resolution: '',
        date: ''
    });
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedIncident, setSelectedIncident] = useState(null);
    const [isTouched, setIsTouched] = useState(false);

    const id = localStorage.getItem('id');

    useEffect(() => {
        fetchIncidents();
    }, [id]);

    const fetchIncidents = async () => {
        axios.get(`${BASE_URL}/incident-reports`)
            .then(response => {
                setIncidents(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
        };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewIncident(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setIsTouched(true);
        setSelectedIncident(prev => ({ ...prev, [name]: value }));
      };

    const openModal = (incident = null) => {
        setSelectedIncident(incident || { type: '', description: '', actions_taken: '', resolution: '' });
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
        setSelectedIncident(null);
        setIsTouched(false);
      };
      const saveIncident = async () => {
        const userId = localStorage.getItem('id');
        const incidentData = { ...selectedIncident, user_id: userId };
        console.log(incidentData);
        if (!selectedIncident.id) {
          const response = await fetch(`${BASE_URL}/incident-reports/${selectedIncident.id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(incidentData)
          });
          if (response.ok) {
            fetchIncidents();
          }
        } else {
          const response = await fetch(`${BASE_URL}/incident-reports/${selectedIncident.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(incidentData)
          });
          if (response.ok) {
            fetchIncidents();
          }
        }
        closeModal();
      };
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${BASE_URL}/incident-reports`, newIncident)
            .then(response => {
                setIncidents([...incidents, response.data]);
                setNewIncident({
                    user_id: localStorage.getItem('id'),
                    type: '',
                    description: '',
                    actions_taken: '',
                    resolution: '',
                    date: ''
                });
            })
            .catch(error => {
                console.error("Error adding incident:", error.message);
            });
    };
    const updateResponseStatus = (id, newStatus) => {
        setIncidents(incidents.map(incident =>
            incident.id === id ? { ...incident, status: newStatus } : incident
        ));
    };
    return (
      <div className="incident-response">
        <h2>Incident Response</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="type"
            placeholder="Type"
            value={newIncident.type}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="description"
            placeholder="Description"
            value={newIncident.description}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="actions_taken"
            placeholder="Actions Taken"
            value={newIncident.actions_taken}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="resolution"
            placeholder="Resolution"
            value={newIncident.resolution}
            onChange={handleInputChange}
            required
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ marginRight: "10px" }}>Select Date:</label>
            <input
              type="date"
              name="date"
              value={newIncident.date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <button type="submit">Add New Incident</button>
          </div>
        </form>
        <Modal
          isOpen={modalIsOpen}
          className="modal-content-healthcareadmin"
          overlayClassName="modal-overlay"
          onRequestClose={closeModal}
          contentLabel="Add/Edit Facility Modal"
        >
          <form
            className="modal-form"
            onSubmit={(e) => {
              e.preventDefault();
              saveIncident();
            }}
          >
            <h2>Edit Incident</h2>
            <label>
              Description:
              <input
                type="text"
                name="description"
                required
                value={selectedIncident?.description}
                onChange={handleEditInputChange}
              />
            </label>
            <label>
              Actions taken:
              <input
                name="actions_taken"
                required
                value={selectedIncident?.actions_taken}
                onChange={handleEditInputChange}
              />
            </label>
            <label>
              Resolution:
              <input
                name="resolution"
                required
                value={selectedIncident?.resolution}
                onChange={handleEditInputChange}
              />
            </label>
            <div className="modal-buttons">
              <button type="button" onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </button>
              <button type="submit" disabled={!isTouched}>
                <FontAwesomeIcon icon={faSave} /> Save Changes
              </button>
            </div>
          </form>
        </Modal>
        <ul className="incident-list">
          {incidents.map((incident) => (
            <li
              key={incident.id}
              className="incident-item"
              style={{ textAlign: "left" }}
            >
              <div className="incident-details">
                <h3>Type: {incident.type}</h3>
                <p>Description: {incident.description}</p>
                <p>Resolution: {incident.resolution}</p>
                <p>Actions taken: {incident.actions_taken}</p>
              </div>
              <div className="incident-actions">
                <button
                  className="edit-btn"
                  onClick={() => openModal(incident)}
                >
                  Edit
                </button>
                {incident.status === "Unresolved" && (
                  <button
                    className="status-btn"
                    onClick={() =>
                      updateResponseStatus(incident.id, "Resolved")
                    }
                  >
                    Mark as Resolved
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
};

export default IncidentResponseComponent;