import axios from "axios";
import React, { useState } from "react";
import "../../styles/DashboardUser.css";
import { BASE_URL } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

 
 
const SymptomCheckerComponent = () => {

  const [symptomDescription, setSymptomDescription] = useState("");
  const [symptomData, setSymptomData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

 
  const handleDescriptionChange = (event) => {
    setSymptomDescription(event.target.value);
  };
 
  const handleCheckSymptoms = async () => {
    try {
      setIsLoading(true);
      setSymptomData("");
      const response = await axios.post(`${BASE_URL}/openai`, {
        prompt: `Patient reports symptoms of ${symptomDescription}. What could be the potential causes?`,
      });
      setSymptomData(response.data.choices[0].message.content);
      console.log(response.data.choices[0].message.content);
      setSymptomDescription("");
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const lines = symptomData.split("\n");
 
  const causes = lines.filter(
    (line) => line && !line.startsWith("Some potential")
  );
 
  return (
    <div className="symptom-checker-component">
     <h2>Symptom Checker</h2>
      <input
        type="text"
        placeholder="Describe your symptoms"
        className="symptom-input"
        value={symptomDescription}
        onChange={handleDescriptionChange}
      />
      <button className="button-check-symptoms" onClick={handleCheckSymptoms}>
        Check Symptoms
      </button>
      {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
    ) : (
      symptomData && (
        <div className="card">
        <h2>Potential Causes</h2>
        {causes.length > 0 ? (
          <ul>
            {causes.map((cause, index) => (
              <li key={index}>{cause}</li>
            ))}
          </ul>
        ) : (
          <p>No potential causes found.</p>
        )}
      </div>
      )
      )}
    </div>
  );
};
 
export default SymptomCheckerComponent;
 