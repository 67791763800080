import React, { useState } from 'react';
import '../styles/ForgotPasswordPage.css';
import emailjs from 'emailjs-com';
import { RESET_URL } from '../utils';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');


    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            // Sending email using EmailJS
            await emailjs.send('service_maxiv28','template_inlwyqn', {
                email: String(email),
                url: `${RESET_URL}/reset-password?email=`
            },'g4A6CHojcuRr5PqXA')
            .then(function(response) {
                console.log('SUCCESS!', response.status, response.text);
             }, function(error) {
                console.log('FAILED...', error);
             });            
            // Update message state
            setMessage('Please check your email inbox, a password reset link will be sent.');
        } catch (error) {
            console.error('Error sending email:', error);
            setMessage('Failed to send reset link. Please try again later.'); // Display error message
        }
    };

    return (
        <div className="forgot-password-container">
            <form onSubmit={handleSubmit} noValidate>
                <h2>Forgot Password</h2>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-actions">
                    <button type="submit">Send Reset Link</button>
                </div>
                {message && <p className="message">{message}</p>}
            </form>
        </div>
    );
};

export default ForgotPasswordPage;
