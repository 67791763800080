import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/PatientRecordsComponent.css';
import { BASE_URL } from '../utils';
import Modal from 'react-modal';

const PatientRecordsComponent = () => {
    const [patientRecords, setPatientRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingRecord, setEditingRecord] = useState(null);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersResponse = await axios.get(`${BASE_URL}/users`);
                const recordsResponse = await axios.get(`${BASE_URL}/medical-records`);
                const recordsWithNames = recordsResponse.data.map(record => {
                    const user = usersResponse.data.users.find(user => user.id === record.user_id);
                    return { ...record, patientName: user ? user.name : 'Unnamed Patient' };
                });
                setPatientRecords(recordsWithNames);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setError('Failed to fetch data');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleDeleteRecord = async (recordId) => {
        try {
            await axios.delete(`${BASE_URL}/medical-records/${recordId}`);
            setPatientRecords(prevRecords => prevRecords.filter(record => record.id !== recordId));
        } catch (error) {
            console.error('Failed to delete record:', error);
            setError('Failed to delete record');
        }
    };

    const handleEditRecord = async (updatedRecord) => {
        try {
            const response = await axios.put(`${BASE_URL}/medical-records/${editingRecord.id}`, updatedRecord);
            setPatientRecords(prevRecords => prevRecords.map(record => record.id === editingRecord.id ? { ...response.data, user_id: record.user_id } : record));
            setEditingRecord(null);
        } catch (error) {
            console.error('Failed to update record:', error);
            setError('Failed to update record');
        }
    };

    const openEditModal = (record) => {
        setEditingRecord({ ...record });
        setEditModalIsOpen(true);
    };

    const closeEditModal = () => {
        setEditingRecord(null);
        setEditModalIsOpen(false);
    };

    const handleInputChange = (field, value) => {
        setEditingRecord(prev => ({ ...prev, [field]: value }));
    };

    return (
        <div className="patient-records-component">
            <h2>Patient Records</h2>
            {loading ? <p>Loading...</p> : patientRecords.length > 0 ? (
                <div className="patient-records-list">
                    {patientRecords.map((record) => (
                        <div key={record.id} className="patient-record-item">
                            <h3>{record.patientName}</h3>
                            <p><strong>Conditions:</strong> {record.conditions || 'None'}</p>
                            <p><strong>Medications:</strong> {record.medical_history || 'None'}</p>
                            <p><strong>Allergies:</strong> {record.allergies || 'None'}</p>
                            <p><strong>BloodGroup:</strong> {record.blood_group || 'None'}</p>
                            <p><strong>immunizations</strong> {record.immunizations || 'None'}</p>
                            <p><strong>vital_signs:</strong> {record.vital_signs || 'None'}</p>
                            <p><strong>lab_results:</strong> {record.lab_results || 'None'}</p>
                            <button onClick={() => openEditModal(record)} className="button-edit-record">Edit Record</button>
                            <button onClick={() => handleDeleteRecord(record.id)} className="button-delete-record">Delete Record</button>
                        </div>
                    ))}
                </div>
            ) : (
                <p>{error || 'No patient records found.'}</p>
            )}

            {editingRecord && (
                <Modal
                    isOpen={editModalIsOpen}
                    className="modal-edit-content"
                    overlayClassName="modal-edit-overlay"
                    onRequestClose={closeEditModal}
                    contentLabel="Edit Record Modal"
                >
                    <span className="close-button" onClick={closeEditModal}>&times;</span>
                    <h3>Edit Record for {editingRecord.patientName}</h3>
                    <form className="modal-form" onChange={handleInputChange}>
                        <div className="health-record-section">
                            <h3>Conditions</h3>
                            <input
                                type="text"
                                value={editingRecord.conditions}
                                onChange={(e) => handleInputChange('conditions', e.target.value)}
                            />
                        </div>
                        <div className="health-record-section">
                            <h3>Medications</h3>
                            <input
                                type="text"
                                value={editingRecord.medications}
                                onChange={(e) => handleInputChange('medications', e.target.value)}
                            />
                        </div>
                        <div className="health-record-section">
                            <h3>Allergies</h3>
                            <input
                                type="text"
                                value={editingRecord.allergies}
                                onChange={(e) => handleInputChange('allergies', e.target.value)}
                            />
                        </div>
                        <div className="health-record-section">
                            <h3>Blood Group</h3>
                            <input
                                type="text"
                                value={editingRecord.blood_group}
                                onChange={(e) => handleInputChange('blood_group', e.target.value)}
                            />
                        </div>
                        <div className="health-record-section">
                            <h3>Prescriptions</h3>
                            <input
                                type="text"
                                value={editingRecord.prescriptions}
                                onChange={(e) => handleInputChange('prescriptions', e.target.value)}
                            />
                        </div>
                        <div className="health-record-section">
                            <h3>Appointments</h3>
                            <input
                                type="text"
                                value={editingRecord.appointments}
                                onChange={(e) => handleInputChange('appointments', e.target.value)}
                            />
                        </div>
                        <div className="health-record-section">
                            <h3>Immunizations</h3>
                            <input
                                type="text"
                                value={editingRecord.immunizations}
                                onChange={(e) => handleInputChange('immunizations', e.target.value)}
                            />
                        </div>
                        <div className="health-record-section">
                            <h3>Vital Signs</h3>
                            <input
                                type="text"
                                value={editingRecord.vitalSigns}
                                onChange={(e) => handleInputChange('vitalSigns', e.target.value)}
                            />
                        </div>
                        <div className="health-record-section">
                            <h3>Lab Results</h3>
                            <input
                                type="text"
                                value={editingRecord.labResults}
                                onChange={(e) => handleInputChange('labResults', e.target.value)}
                            />
                        </div>

                    </form>
                    <div className="modal-buttons">
                        <button onClick={closeEditModal}>
                            Cancel
                        </button>
                        <button onClick={() => handleEditRecord(editingRecord)}>
                            Save Changes
                        </button>
                    </div>
                </Modal>
            )}

        </div>
    );
};

export default PatientRecordsComponent;
