import React, { useState, useEffect } from 'react';
import '../../styles/DashboardHealthCareAdministrator.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { BASE_URL } from '../../utils';

const FacilityManagementComponent = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    fetchFacilities();
  }, []);

  const fetchFacilities = async () => {
    const response = await fetch(`${BASE_URL}/facilities`);
    const data = await response.json();
    setFacilities(data);
  };

  const openModal = (facility = null) => {
    setSelectedFacility(facility || { name: '', address: '', services: '', status: 'active' });
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedFacility(null);
    setIsTouched(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIsTouched(true);
    setSelectedFacility(prev => ({ ...prev, [name]: value }));
  };

  const saveFacility = async () => {
    const userId = localStorage.getItem('id');
    const facilityData = { ...selectedFacility, user_id: userId };

    if (!selectedFacility.id) {
      const response = await fetch(`${BASE_URL}/facilities`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(facilityData)
      });
      if (response.ok) {
        fetchFacilities();
      }
    } else {
      const response = await fetch(`${BASE_URL}/facilities/${selectedFacility.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(facilityData)
      });
      if (response.ok) {
        fetchFacilities();
      }
    }
    closeModal();
  };

  const toggleFacilityStatus = async (id) => {
    const facility = facilities.find(f => f.id === id);
    const updatedStatus = facility.status === 'active' ? 'inactive' : 'active';
    const response = await fetch(`${BASE_URL}/facilities/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...facility, status: updatedStatus })
    });
    if (response.ok) {
      fetchFacilities();
    }
  };

  return (
    <div className="facility-management">
      <h2>Facility Management</h2>
      <button onClick={() => openModal()} className="add-facility-btn">Add New Facility</button>

      <Modal
        isOpen={modalIsOpen}
        className="modal-content-healthcareadmin"
        overlayClassName="modal-overlay"
        onRequestClose={closeModal}
        contentLabel="Add/Edit Facility Modal"
      >
        <h2 style={{ display: "flex", justifyContent: "center" }}>
          {selectedFacility?.id ? 'Edit Facility' : 'Add New Facility'}
        </h2>
        <form className="modal-form" onSubmit={(e) => {
          e.preventDefault();
          saveFacility();
        }}>
          <label>
            Facility Name:
            <input type="text" name="name" required value={selectedFacility?.name} onChange={handleInputChange} />
          </label>
          <label>
            Address:
            <input type="text" name="address" required value={selectedFacility?.address} onChange={handleInputChange} />
          </label>
          <label>
            Services:
            <input type="text" name="services" required value={selectedFacility?.services} onChange={handleInputChange} />
          </label>
          <label>
            Status:
            <select name="status" value={selectedFacility?.status} onChange={handleInputChange}>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </label>
          <div className="modal-buttons">
            <button type="button" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </button>
            <button type="submit" disabled={!isTouched}>
              <FontAwesomeIcon icon={faSave} /> Save Changes
            </button>
          </div>
        </form>
      </Modal>

      <ul className="facility-list">
        {facilities?.map(facility => (
          <li key={facility.id} className="facility-item" style={{ textAlign: "left" }}>
            <div className="facility-details">
              <h3>{facility.name}</h3>
              <p>Address: {facility.address}</p>
              <p>Services: {facility.services}</p>
              <p>Status: {facility.status}</p>
            </div>
            <div className="facility-actions">
              <button className="edit-btn" onClick={() => openModal(facility)}>Edit</button>
              <button className="status-btn" onClick={() => toggleFacilityStatus(facility.id)}>
                {facility.status === 'active' ? 'Deactivate' : 'Activate'}
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FacilityManagementComponent;