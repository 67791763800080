import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/admin.css';
import { BASE_URL } from '../../utils';


const MaintenanceModeComponent = () => {
    const [maintenanceStatus, setMaintenanceStatus] = useState('Yes');
    const [selectedStatus, setSelectedStatus] = useState('Yes');

    useEffect(() => {
        axios.get(`${BASE_URL}/maintenances/1`)
            .then(response => {
                setMaintenanceStatus(response.data.status);
                setSelectedStatus(response.data.status);
            })
            .catch(error => {
                console.error('Error fetching maintenance status', error);
            });
    }, []);

    const saveStatus = () => {
        axios.put(`${BASE_URL}/maintenances/1`, {
            status: selectedStatus
        })
            .then(() => {
                setMaintenanceStatus(selectedStatus);
                alert('Maintenance status updated successfully');
            })
            .catch(error => {
                console.error('Error updating maintenance status', error);
            });
    };

    return (
        <div className="maintenance-mode">
            <h2>Maintenance Mode</h2>
            <div className="status-control">
                <label htmlFor="status">Allow Registrations: <strong>{maintenanceStatus}</strong> </label>
                <select id="status" value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
                <button onClick={saveStatus}>Save</button>
            </div>
        </div>
    );
};

export default MaintenanceModeComponent;
