import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ProfilePage from './pages/ProfilePage';
import Navbar from './components/NavBarComponent';
import HealthCareProviderDashboard from './pages/DashboardHealthCareProvider';
import { AuthProvider } from './AuthContext';
import UserDashboard from './pages/DashboardUser';
import AdminDashboard from './pages/DashboardAdmin';
import PharmacistDashboard from './pages/DashboardPharmacist';
import FeaturesPage from './pages/FeaturesPage';
import ContactPage from './pages/Contact';
import AboutPage from './pages/AboutPage';
import './App.css';
import MessagesPage from './components/Messages';
import HealthcareAdministratorDashboard from './pages/DashboardHealthCareAdministrator';
import ResetPassword from '../src/pages/ResetPassword';

const AppRouter = () => {
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <div className="main-content">

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path='profile' element={<ProfilePage />} />
            <Route path='/healthcareprovider-dashboard' element={<HealthCareProviderDashboard />} />
            <Route path='/user-dashboard' element={<UserDashboard />} />
            <Route path='admin-dashboard' element={<AdminDashboard />} />
            <Route path='pharmacist-dashboard' element={<PharmacistDashboard />} />
            <Route path='features-page' element={<FeaturesPage />} />
            <Route path='contact-page' element={<ContactPage />} />
            <Route path='about-page' element={<AboutPage />} />
            <Route path='/messages' element={<MessagesPage />} />
            <Route path='/healthcareadministrator-dashboard' element={<HealthcareAdministratorDashboard />} />
            <Route path="/reset-password" element={<ResetPassword />} />

          </Routes>
        </div>
        <footer className="home-footer">
          <p>&copy; {new Date().getFullYear()} SmartHealth Hub. All rights reserved.</p>
          <p>Contact us at <a href="mailto:info@smarthealthhub.com">info@smarthealthhub.com</a></p>
        </footer>

      </AuthProvider>
    </Router>
  );
};

export default AppRouter;
