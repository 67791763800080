import React, { useState } from 'react';
import AppointmentsComponent from '../components/AppointmentsComponent';
import ForumComponent from '../components/ForumComponent';
import MessagesComponent from '../components/MessagesComponent';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HCPEPrescriptionsComponent from '../components/HealthCareProvider/E-prescriptions';

import HCPHealthAnalyticsComponent from '../components/HealthCareProvider/hcp_analytics';

import '../styles/DashboardHealthCareProvider.css';
import PatientRecordsComponent from '../components/PatientRecordsComponent';
import MessagesPage from '../components/Messages';


const HealthCareProviderDashboard = () => {
    const [currentTab, setCurrentTab] = useState('prescriptions');
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    
        if (!isLoggedIn) {
            navigate('/login');
        } 
    }, [navigate]);
    // Dummy data for Appointments
    const appointments = [
        { id: 1, patientName: 'Micheal Ross', date: '2024-08-01', time: '09:00 AM', purpose: 'Routine Checkup' },
        { id: 2, patientName: 'Jane Smith', date: '2024-08-02', time: '10:30 AM', purpose: 'Dental Cleaning' },
    ];

    const healthAnalytics = {
        lastCheckup: '2024-07-20',
        bloodPressure: '120/80',
        cholesterol: '190'
    };


    // Dummy data for Patient Records
    const patientRecords = [
        { id: 1, patientName: 'Micheal Ross', age: 30, conditions: ['Diabetes', 'Hypertension'], medications: ['Metformin', 'Lisinopril'], allergies: ['Penicillin'] },
        { id: 2, patientName: 'Jane Smith', age: 25, conditions: ['Asthma'], medications: ['Albuterol'], allergies: ['Aspirin'] },
    ];

    // Dummy data for Prescriptions
    const prescriptions = [
        { id: 1, medicationName: 'Metformin', dosage: '500mg', frequency: 'Twice a day', patientName: 'Micheal Ross' },
        { id: 2, medicationName: 'Albuterol', dosage: '2 puffs', frequency: 'As needed', patientName: 'Jane Smith' },
    ];

    // Dummy data for Messages
    const messages = [
        { id: 1, sender: 'Micheal Ross', content: 'I have a question about my prescription', received: '2024-07-20 14:30' },
        { id: 2, sender: 'Jane Smith', content: 'Can I schedule an appointment?', received: '2024-07-20 15:45' },
    ];

    // Dummy data for Forum Threads
    const threads = [
        { id: 1, title: 'Managing Diabetes', content: 'Discussion on effective diabetes management strategies.', lastUpdated: '2024-07-18' },
        { id: 2, title: 'Benefits of Regular Checkups', content: 'Why are regular checkups important? Share your thoughts.', lastUpdated: '2024-07-19' },
    ];



    return (
        <div className="healthcare-provider-dashboard">
            <h1>Healthcare Provider Dashboard</h1>
            <div className="dashboard-tabs">
            <button onClick={() => setCurrentTab('prescriptions')} className={currentTab === 'prescriptions' ? 'active' : ''}>Prescription Management</button>
                <button onClick={() => setCurrentTab('appointments')} className={currentTab === 'appointments' ? 'active' : ''}>Appointment Management</button>
                <button onClick={() => setCurrentTab('patientRecords')} className={currentTab === 'patientRecords' ? 'active' : ''}>Patient Health Records</button>
                <button onClick={() => setCurrentTab('healthAnalytics')} className={currentTab === 'healthAnalytics' ? 'active' : ''}>Health Analytics</button>
                <button onClick={() => setCurrentTab('forum')} className={currentTab === 'forum' ? 'active' : ''}>Forum Contributions</button>
                <button onClick={() => setCurrentTab('messages')} className={currentTab === 'messages' ? 'active' : ''}>Messages</button>    
            </div>
            <div className="dashboard-content">
                {currentTab === 'prescriptions' && <div className="component-container"><HCPEPrescriptionsComponent /></div>}
                {currentTab === 'appointments' && <div className="component-container"><AppointmentsComponent appointments={appointments} /></div>}
                {currentTab === 'patientRecords' && <div className="component-container"><PatientRecordsComponent patientRecords={patientRecords} /></div>}
                {currentTab === 'healthAnalytics' && <HCPHealthAnalyticsComponent analytics={healthAnalytics} />}
                {currentTab === 'forum' && <div className="component-container"><ForumComponent threads={threads} /></div>}
                {currentTab === 'messages' && <div className="component-container"><MessagesPage messages={messages} /></div>}
                
            </div>

        </div>
    );
};

export default HealthCareProviderDashboard;
