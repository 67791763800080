import React from 'react';
import '../styles/AboutPage.css';

function AboutUsPage() {
    return (
        <div className="about-container">
            <h1 className="about-title">About SmartHealth Hub</h1>
            <div className="about-content">
            <p>Welcome to <b>SmartHealth Hub</b>, where healthcare meets innovation to create a seamless and empowering 
                experience for everyone involved. At SmartHealth Hub, we're on a mission to revolutionize the way healthcare
                 is accessed and managed, integrating cutting-edge technology with a focus on user experience and data security.</p>

            <p>Our journey began with a vision to bridge the gap between healthcare and technology, recognizing the critical
                 need for an integrated healthcare ecosystem. We believe that everyone deserves easy access to comprehensive 
                 healthcare services, and our platform is designed to make that vision a reality.</p>

            <p>SmartHealth Hub is not just a website; it's a dynamic healthcare solution that puts you, the user, at the 
                center. Whether you're a patient seeking personalized care, a healthcare provider striving for efficiency,
                 or an administrator ensuring seamless operations, SmartHealth Hub is here to empower you every step of the
                  way.</p>

            <p>With features like Personal Health Records (PHR), Appointment Scheduling, Symptom Checker, Medication 
                Reminders, and Secure Messaging, we've created a platform that caters to your every healthcare need. 
                Our commitment to user-friendly design and robust security ensures that your health information remains
                 confidential and accessible whenever you need it.</p>

            <p>But SmartHealth Hub is more than just a platform; it's a community. Our Health Forums provide a space for 
                users to connect, share experiences, and seek advice from peers and professionals alike. We believe in the power
                 of collaboration and knowledge-sharing to drive positive health outcomes for everyone.</p>

            <p>Behind SmartHealth Hub is a dedicated team of professionals, each playing a vital role in bringing our
                 vision to life. From developers crafting a seamless user experience to administrators ensuring data 
                 integrity and compliance, our team works tirelessly to make SmartHealth Hub the gold standard in integrated 
                 healthcare solutions.</p>

            <p>At SmartHealth Hub, we're not just building a website; we're building a healthier future for generations
                 to come. Join us on this journey towards better healthcare accessibility, improved outcomes, and 
                 empowered individuals. Together, we can make a difference in the way healthcare is delivered and 
                 experienced. Welcome to SmartHealth Hub, where healthcare meets innovation.</p>

            </div>
        </div>
    );
}

export default AboutUsPage;
