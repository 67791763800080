
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../utils';

const MedicationHistoryComponent = () => {
    const [prescriptions, setPrescriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchPrescriptions();
       
    }, []);

      const fetchPrescriptions = async () => {
        
        try {
            const providersResponse = await axios.get(`${BASE_URL}/providers`);
            const fetchedProviders = providersResponse.data;
            const userId = localStorage.getItem('id');
            const response = await axios.get(`${BASE_URL}/prescriptions`);
            const filteredPrescriptions = response.data.filter(prescription => prescription.pharmacist_id === parseInt(userId) && prescription.dispense === "Dispensed");
            let prescriptionsWithNames = filteredPrescriptions.map(prescription => {
                const provider = fetchedProviders.find(provider => provider.id === prescription.healthcare_provider_id);
                return { ...prescription, provider_name: provider? provider.provider_name : 'Unnamed provider' };
            });
            setPrescriptions(prescriptionsWithNames);
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch prescriptions:', error);
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="prescriptions-list">
            <h2>Prescriptions</h2>
            {prescriptions.length > 0 ? (
                <ul>
                    {prescriptions.map(prescription => (
                        <li key={prescription.id} style={{ textAlign: "left" }}>
                            <p>Provider Name: {prescription.provider_name}</p>
                            <p>User Email: {prescription.user_email}</p>
                            <p>Medication: {prescription.medication_name}</p>
                            <p>Dosage: {prescription.dosage}</p>
                            <p>Frequency: {prescription.frequency}</p>
                            <p>Start Date: {prescription.start_date}</p>
                            <p>End Date: {prescription.end_date}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No Prescriptions dispensed.</p>
            )}
        </div>
    );
};

export default MedicationHistoryComponent;
