import React, { useState, useEffect } from 'react';
import '../styles/DashboardPharmacist.css';
import MedicationHistoryComponent from '../components/Pharmacist/PharmacistMedicationHistoryComponent';
import MedicationDispensationComponent from '../components/MedicationDispensationComponent';
import MessagesPage from '../components/Messages';
import { useNavigate } from 'react-router-dom';

const PharmacistDashboard = () => {
    const [currentTab, setCurrentTab] = useState('medicationHistory');
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    
        if (!isLoggedIn) {
            navigate('/login');
        } 
    }, [navigate]);
    const medicationHistory = [

    ];

    return (
        <div className="pharmacist-dashboard">
            <h1>Pharmacist Dashboard</h1>
            <div className="dashboard-tabs">
                {/* <button onClick={() => setCurrentTab('dispensationRecords')} className={currentTab === 'dispensationRecords' ? 'active' : ''}>Dispensation Records</button> */}
                <button onClick={() => setCurrentTab('medicationHistory')} className={currentTab === 'medicationHistory' ? 'active' : ''}>Medication History</button>
                <button onClick={() => setCurrentTab('dispenseMedication')} className={currentTab === 'dispenseMedication' ? 'active' : ''}>Dispense Medication</button>
                <button onClick={() => setCurrentTab('messages')} className={currentTab === 'messages' ? 'active' : ''}>Messages</button>
            </div>
            <div className="dashboard-content">
                {/* {currentTab === 'dispensationRecords' && <div className="component-container"><MedicationDispensationRecordsComponent records={dispensationRecords} /></div>} */}
                {currentTab === 'medicationHistory' && <div className="component-container"><MedicationHistoryComponent history={medicationHistory} /></div>}
                {currentTab === 'dispenseMedication' && <div className="component-container"><MedicationDispensationComponent /></div>}
                {currentTab === 'messages' && <div className="component-container"><MessagesPage /></div>}
            </div>
        </div>
    );
};

export default PharmacistDashboard;