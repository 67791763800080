import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

const HealthcareProviderProfileManagementComponent = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedSpecialty, setSelectedSpecialty] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedRating, setSelectedRating] = useState('');
    const [selectedProviderName, setSelectedProviderName] = useState('');
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let tempErrors = {};
        if (!selectedProviderName) tempErrors.providerName = "Please fill this field to save changes.";
        if (!selectedSpecialty) tempErrors.specialty = "Please fill this field to save changes.";
        if (!selectedLocation) tempErrors.location = "Please fill this field to save changes.";
        if (!selectedRating) tempErrors.rating = "Please fill this field to save changes.";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const openModal = (provider) => {
        setSelectedProvider(provider);
        setSelectedProviderName(provider.provider_name);
        setSelectedSpecialty(provider.specialty);
        setSelectedLocation(provider.location);
        setSelectedRating(provider.rating);
        setIsOpen(true);
        setErrors({});
    };
    const closeModal = () => setIsOpen(false);

    const openCancelModal = (provider) => {
        setSelectedProvider(provider);
        setCancelModalIsOpen(true);
    };
    const closeCancelModal = () => setCancelModalIsOpen(false);

    useEffect(() => {
        fetchProviders();
    }, []);

    const fetchProviders = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/providers`);
          setProviders(response.data);
        } catch (error) {
          console.error('Error fetching providers:', error);
        }
    };

    const saveChanges = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        try {
          const updatedProvider = {
            provider_name: selectedProviderName,
            specialty: selectedSpecialty,
            location: selectedLocation,
            rating: selectedRating,
          };
          const url = `${BASE_URL}/provider/${selectedProvider.id}`;
          const response = await axios.put(url, updatedProvider);

          setProviders(providers.map(provider =>
            provider.id === selectedProvider.id ? response.data : provider
          ));
      
          closeModal();
        } catch (error) {
          console.error('Error updating provider:', error);
        }
    };

    const handleConfirmCancel = async () => {
        if (!selectedProvider) return;
        try {
            await axios.delete(`${BASE_URL}/provider/${selectedProvider.id}`);
            setProviders(providers.filter(app => app.id !== selectedProvider.id));
            closeCancelModal();
        } catch (error) {
            console.error('Error cancelling provider:', error);
        }
    };

    return (
        <div className="provider-profile-management">
          <h2>Healthcare Provider Profiles</h2>
          {providers.map((provider) => (
            <div key={provider.id} className="provider-profile-item">
              <h3>{provider.provider_name}</h3>
              <p>Specialty: {provider.specialty}</p>
              <p>Location: {provider.location}</p>
              <p>Rating: {provider.rating} stars</p>
              <button onClick={() => openModal(provider)} className="button-edit">Edit Profile</button>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)"
                  },
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    width: "40%",
                    height: "auto"
                  }
                }}
                contentLabel="Edit Profile Modal"
              >
                <h2>Edit Profile</h2>
                <form className="modal-form" onSubmit={saveChanges}>
                  <label>
                    Healthcare Provider Name:
                    <input
                      type="text"
                      value={selectedProviderName}
                      onChange={(e) => setSelectedProviderName(e.target.value)}
                    />
                    {errors.providerName && <p className="error">{errors.providerName}</p>}
                  </label>
                  <label>
                    Specialty:
                    <textarea
                      value={selectedSpecialty}
                      onChange={(e) => setSelectedSpecialty(e.target.value)}
                    />
                    {errors.specialty && <p className="error">{errors.specialty}</p>}
                  </label>
                  <label>
                    Location:
                    <textarea
                      value={selectedLocation}
                      onChange={(e) => setSelectedLocation(e.target.value)}
                    />
                    {errors.location && <p className="error">{errors.location}</p>}
                  </label>
                  <label>
                    Rating:
                    <textarea
                      value={selectedRating}
                      onChange={(e) => setSelectedRating(e.target.value)}
                    />
                    {errors.rating && <p className="error">{errors.rating}</p>}
                  </label>
                  <div className="modal-buttons">
                    <button type="button" onClick={closeModal}>
                      <FontAwesomeIcon icon={faTimes} /> Cancel
                    </button>
                    <button type="submit">
                      <FontAwesomeIcon icon={faSave} /> Save Changes
                    </button>
                  </div>
                </form>
              </Modal>
              <Modal
                isOpen={cancelModalIsOpen}
                onRequestClose={closeCancelModal}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)"
                  },
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    width: "30%",
                    height: "auto"
                  }
                }}
                contentLabel="Cancel Profile Modal"
              >
                <h2>Confirm Profile Deletion</h2>
                <div className="modal-buttons">
                  <button onClick={closeCancelModal}>
                    <FontAwesomeIcon icon={faTimes} /> No
                  </button>
                  <button onClick={handleConfirmCancel}>Yes, Delete It</button>
                </div>
              </Modal>
              <button className="button-delete" onClick={() => openCancelModal(provider)}>Delete Profile</button>
            </div>
          ))}
        </div>
    );
};

export default HealthcareProviderProfileManagementComponent;
