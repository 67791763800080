import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FacilityManagementComponent from '../components/HealthCareAdministrator/FacilityManagementComponent';
import StaffCoordinationComponent from '../components/HealthCareAdministrator/StaffCoordinationComponent';
import '../styles/DashboardHealthCareAdministrator.css';

import IncidentResponseComponent from '../components/HealthCareAdministrator/IncidentResponseComponent';

import ComplianceOversightComponent from '../components/HealthCareAdministrator/ComplianceOversightComponent';

const HealthcareAdministratorDashboard = () => {
    const [currentTab, setCurrentTab] = useState('facilityManagement');
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    
        if (!isLoggedIn) {
            navigate('/login');
        } 
    }, [navigate]);
    return (
        <div className="healthcare-admin-dashboard">
            <h1>Healthcare Administrator Dashboard</h1>
            <div className="dashboard-tabs">
                <button onClick={() => setCurrentTab('facilityManagement')} className={currentTab === 'facilityManagement' ? 'active' : ''}>Facility Management</button>
                <button onClick={() => setCurrentTab('staffCoordination')} className={currentTab === 'staffCoordination' ? 'active' : ''}>Staff Coordination</button>
                <button onClick={() => setCurrentTab('incidentResponse')} className={currentTab === 'incidentResponse' ? 'active' : ''}>Incident Response</button>
                <button onClick={() => setCurrentTab('complianceOversight')} className={currentTab === 'complianceOversight' ? 'active' : ''}>Compliance Oversight</button>
            </div>
            <div className="dashboard-content">
                {currentTab === 'facilityManagement' && <FacilityManagementComponent />}
                {currentTab === 'staffCoordination' && <StaffCoordinationComponent />}
                {currentTab === 'incidentResponse' && <IncidentResponseComponent />}
                {currentTab === 'complianceOversight' && <ComplianceOversightComponent />}
            </div>
        </div>
    );
};

export default HealthcareAdministratorDashboard;
