import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import '../../styles/DashboardUser.css';
import { BASE_URL } from '../../utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const HealthAnalyticsComponent = () => {
    const [chartData, setChartData] = useState({});
    const [patientRecords, setPatientRecords] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/prescriptions`);
                processChartData(response.data);
            } catch (error) {
                console.error('Failed to fetch prescriptions:', error);
            }
        };
        fetchUsers();
        fetchPatientRecords();
        fetchData();
    }, []);

    const fetchUsers = async () => {
        try {
            const usersResp = await axios.get(`${BASE_URL}/users`);
            setUsers(usersResp.data.users);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchPatientRecords = async () => {
        try {
            const recordsResponse = await axios.get(`${BASE_URL}/medical-records`);
            setPatientRecords(recordsResponse.data);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    const processChartData = (prescriptions) => {
        const prescriptionCounts = {};
        prescriptions.forEach(prescription => {
            const { user_email } = prescription;
            prescriptionCounts[user_email] = (prescriptionCounts[user_email] || 0) + 1;
        });

        const labels = Object.keys(prescriptionCounts);
        const data = labels.map(label => prescriptionCounts[label]);

        setChartData({
            labels: labels.map(label => `User ${label}`),
            datasets: [{
                label: 'Number of Prescriptions',
                data: data,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderColor: 'rgb(53, 162, 235)',
                borderWidth: 1,
            }]
        });
    };

    return (
        <div className="health-analytics-component">
            <h2>Health Analytics</h2>
            <div className="analytics-dashboard">
                {chartData.labels ? (
                    <Bar data={chartData} options={{
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        },
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Prescription Count per User'
                            }
                        }
                    }} />
                ) : (
                    <p>Loading chart...</p>
                )}
            </div>
        </div>
    );
};

export default HealthAnalyticsComponent;
