import React, { useState, useEffect } from 'react';
import '../styles/DashboardAdmin.css';
import { useNavigate } from 'react-router-dom';
import UserManagementComponent from '../components/Admin/AdminUserManagementComponent';
import SystemConfigurationComponent from '../components/Admin/AdminSystemConfigurationComponent';
import DataReportingComponent from '../components/Admin/AdminDataReportingComponent';
import HealthCareProviderProfileManagementComponent from '../components/Admin/AdminHealthCareProviderProfileManagementComponent.js';
import ReportGenerationComponent from '../components/Admin/ReportGenerationComponent.js';


const AdminDashboard = () => {
    const [currentTab, setCurrentTab] = useState('userManagement');
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    
        if (!isLoggedIn) {
            navigate('/login');
        } 
    }, [navigate]);
    return (
        <div className="admin-dashboard-container">
            <h1>Admin Dashboard</h1>
            <nav className="admin-dashboard-nav">
                <button onClick={() => setCurrentTab('userManagement')} className={currentTab === 'userManagement' ? 'active' : ''}>User Management</button>
                <button onClick={() => setCurrentTab('systemConfiguration')} className={currentTab === 'systemConfiguration' ? 'active' : ''}>System Configuration</button>
                <button onClick={() => setCurrentTab('dataReporting')} className={currentTab === 'dataReporting' ? 'active' : ''}>Data Oversight</button>
                <button onClick={() => setCurrentTab('providerProfileManagement')} className={currentTab === 'providerProfileManagement' ? 'active' : ''}>Provider Profiles</button>

            </nav>
            <main className="admin-dashboard-main">
                {currentTab === 'userManagement' && <UserManagementComponent />}
                {currentTab === 'systemConfiguration' && <SystemConfigurationComponent />}
                {currentTab === 'dataReporting' && <DataReportingComponent />}
                {currentTab === 'providerProfileManagement' && <HealthCareProviderProfileManagementComponent />}
                {currentTab === 'reportGeneration' && <ReportGenerationComponent />}
            </main>
        </div>
    );
};

export default AdminDashboard;