import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/CommunityInteraction.css";
import { BASE_URL } from "../utils";
import Modal from 'react-modal'; 


const ForumComponent = () => {
    const [posts, setPosts] = useState([]);
    const [newPostContent, setNewPostContent] = useState("");
    const [newPostTopic, setNewPostTopic] = useState("");
    const [newPostCategory, setNewPostCategory] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPost, setCurrentPost] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [postToDelete, setPostToDelete] = useState(null);
    const id = localStorage.getItem("id");
    const [errors, setErrors] = useState({
      newPostTopic: '',
      newPostCategory: '',
      newPostContent: '',
    });
    useEffect(() => {
      fetchPosts();
    }, []);
  
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/forums`);
        setPosts(response.data);
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      }
    };
    const findFormErrors = () => {
      const newErrors = {};
      if (!newPostTopic || newPostTopic === '') newErrors.newPostTopic = 'Please enter the topic';
      if (!newPostCategory || newPostCategory === '') newErrors.newPostCategory = 'Please enter the category';
      if (!newPostContent || newPostContent === '') newErrors.newPostContent = 'Please enter the content';
      return newErrors;
    };
  
    const handlePostSubmit = async (event) => {
      event.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        const postData = {
          user_id: id,
          content: newPostContent,
          topic: newPostTopic,
          category: newPostCategory,
        };
        const response = await axios.post(`${BASE_URL}/forums`, postData);
        setPosts([...posts, response.data]);
        fetchPosts();
        setNewPostContent("");
        setNewPostTopic("");
        setNewPostCategory("");
      } catch (error) {
        console.error("Failed to create post:", error);
      }
    }
    };
  
    const openDeleteModal = (post) => {
      setPostToDelete(post);
      setIsDeleteModalOpen(true);
    };
    const handleDeletePost = async () => {
      try {
        await axios.delete(`${BASE_URL}/forums/${postToDelete.id}`);
        setPosts(posts.filter((post) => post.id !== postToDelete.id));
        setIsDeleteModalOpen(false);
      } catch (error) {
        console.error("Failed to delete post:", error);
      }
    };
  
    const handleUpdatePost = async () => {
      try {
        const response = await axios.put(`${BASE_URL}/forums/${currentPost.id}`, {
          content: currentPost.content ,
        });
        const updatedPosts = posts.map((post) =>
          post.id === currentPost.id ? { ...post, ...response.data } : post
        );
        setPosts(updatedPosts);
        setIsModalOpen(false);
      } catch (error) {
        console.error("Failed to update post:", error);
      }
    };
    const openModal = (post) => {
      setCurrentPost({ ...post });
      setIsModalOpen(true);
    };
  
    const handleModalChange = (e) => {
      setCurrentPost({ ...currentPost, content: e.target.value });
    };
  
    return (
      <div className="community-interaction-container">
        <h2>Forum Contributions</h2>
        <input
          type="text"
          value={newPostTopic}
          onChange={(e) => setNewPostTopic(e.target.value)}
          placeholder="Enter topic"
          className="new-post-input"
        />
         {errors.newPostTopic && <p style={{color: "red"}}>{errors.newPostTopic}</p>}
        <input
          type="text"
          value={newPostCategory}
          onChange={(e) => setNewPostCategory(e.target.value)}
          placeholder="Enter category"
          className="new-post-input"
        />
        {errors.newPostCategory && <p style={{color: "red"}}>{errors.newPostCategory}</p>}
        <textarea
          value={newPostContent}
          onChange={(e) => setNewPostContent(e.target.value)}
          placeholder="What's on your mind?"
          className="new-post-textarea"
        ></textarea>
         {errors.newPostContent && <p style={{color: "red"}}>{errors.newPostContent}</p>}
        <button onClick={handlePostSubmit} className="submit-post-button">
          Post
        </button>
        <div className="posts-container" style={{textAlign:"left"}}>
          {posts.map((post) => (
            <div key={post.id} className="post">
              <h3>Forum Name: {post.topic}</h3>
              <p className="timestamp">
                {new Date(post.date_created).toLocaleString()}
              </p>
              <p className="content">{post.content}</p>
              {Number(post.user_id) === Number(id) && (
              <div className="post-actions">
                <button className="update_button" onClick={() => openModal(post)}>
                  Update
                </button>
                <button
                  className="delete_button"
                  onClick={() => openDeleteModal(post)}
                >
                  Delete
                </button>
              </div>
              )}
            </div>
          ))}
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              border: "1px solid rgb(204, 204, 204)",
              background: "rgb(255, 255, 255)",
              overflow: "auto",
              borderRadius: "4px",
              outline: "none",
              padding: "20px",
              width: "40%",
              height: "30vh",
            },
          }}
        >
          <h2>Update Post</h2>
          <textarea
            value={currentPost?.content}
            onChange={handleModalChange}
            style={{
              width: "90%",
              padding: "10px",
              fontSize: "16px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          ></textarea>
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <button className="update_button" onClick={handleUpdatePost}>
              Update Post
            </button>
            <button
              className="close_button"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              border: "1px solid rgb(204, 204, 204)",
              background: "rgb(255, 255, 255)",
              overflow: "auto",
              borderRadius: "4px",
              outline: "none",
              padding: "20px",
              width: "30%",
              height: "auto",
            },
          }}
        >
          <h2>Confirm Delete</h2>
          <p>Are you sure you want to delete this post?</p>
          <button className="update_button" onClick={handleDeletePost}>
            Yes, delete it
          </button>
          <button
            className="close_button"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            No, keep it
          </button>
        </Modal>
      </div>
    );
  };

export default ForumComponent;
