import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from '../AuthContext';
import '../styles/NavBar.css';

const Navbar = () => {
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const role = localStorage.getItem('userRole');

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth < 768);
        });
    }, []);
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleLogout = () => {
        setAuth({ isLoggedIn: false, userRole: '' });
        localStorage.setItem('isLoggedIn', 'false');
        localStorage.removeItem('id');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userName');
        localStorage.removeItem('userRole');
        localStorage.removeItem('provider_id');
        navigate('/');
        setIsMenuOpen(false);
    };

    const dashboardPath = role === 'Healthcare Provider' ? '/healthcareprovider-dashboard' :
    role === 'Administrator' ? '/admin-dashboard' :
    role === 'Health Administrator' ? '/healthcareadministrator-dashboard' :
    role === 'Pharmacist' ? '/pharmacist-dashboard' :
    '/user-dashboard';

    return (
        <nav className="navbar">
            {/* <div className="menu-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <div className={`bar ${isMenuOpen ? 'change' : ''}`}></div>
            </div> */}
            {isMobile && (
                <div className="menu-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <FontAwesomeIcon icon={faBars} />
                    
                </div>)}
            <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                <Link to="/" onClick={closeMenu}>Home</Link>
                <Link to="https://rxa6380.uta.cloud/" onClick={closeMenu}>Blog</Link>
                <Link to="/features-page" onClick={closeMenu}>Services</Link>
                <Link to="/about-page" onClick={closeMenu}>About us</Link>
                <Link to="/contact-page" onClick={closeMenu} >Contact</Link>
                {auth.isLoggedIn && (
                    <>
                        <Link to={dashboardPath} onClick={closeMenu}>Dashboard</Link>
                        <Link to="/messages" onClick={closeMenu}>Messages</Link>
                        <Link to="/" onClick={handleLogout}>Logout</Link>
                    </>
                )}
                {!auth.isLoggedIn && (
                    <>
                        <Link to="/login" onClick={closeMenu}>Login</Link>
                        <Link to="/register" onClick={closeMenu}>Signup</Link>
                    </>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
