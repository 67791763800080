import React from 'react';
import '../../styles/ReportGenerationComponent.css';
import * as XLSX from 'xlsx';

const ReportGenerationComponent = () => {
    const reports = [
        {
            id: 1,
            title: 'User Activity for March 2024',
            description: 'Detailed report on user logins, activity, and engagement for March 2024.',
            data: [
                { username: 'user1', loginCount: 25, activeHours: 15 },
                { username: 'user2', loginCount: 18, activeHours: 12 },
            ],
            date: '2024-03-31',
        },
        {
            id: 2,
            title: 'System Performance Q1 2024',
            description: 'Overview of system uptime, response times, and service health for Q1.',
            data: [
                { service: 'Web Server', uptime: '99.9%', averageResponseTime: '250ms' },
                { service: 'Database Server', uptime: '99.8%', averageResponseTime: '300ms' },
            ],
            date: '2024-03-31',
        },
    ];

    const generateExcelReport = (report) => {
        const workSheet = XLSX.utils.json_to_sheet(report.data);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Report Data');
        // Define file name
        const fileName = `${report.title.replace(/ /g, '_')}.xlsx`;
        // Write file
        XLSX.writeFile(workBook, fileName);
    };

    return (
        <div className="report-generation-container">
            <h2>Report Generation</h2>
            <div className="reports-list">
                {reports.map(report => (
                    <div key={report.id} className="report-item">
                        <h3>{report.title}</h3>
                        <p>{report.description}</p>
                        <span className="report-date">Date: {report.date}</span>
                        <button 
                            className="generate-report-button"
                            onClick={() => generateExcelReport(report)}
                        >
                            Generate Report
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ReportGenerationComponent;
