import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import { endOfMonth } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTimes, faCalendarPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/DashboardUser.css';
import { BASE_URL } from '../../utils';
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement('#root');

const PatientAppointmentsComponent = () => {
    const formRef = useRef(null);
    const [appointments, setAppointments] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
    const [rescheduleModalIsOpen, setRescheduleModalIsOpen] = useState(false);
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const id = localStorage.getItem('id');
    const [selectedProvider, setSelectedProvider] = useState('');
    const [providers, setProviders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [errors, setErrors] = useState({ provider: '', date: '', time: '', location: '' });
    // const [rescheduleErrors, setRescheduleErrors] = useState({ provider: '', date: '', time: '', location: '' });
    const [minTime, setMinTime] = useState("00:00");

    useEffect(() => {
      fetchProviders();
      fetchAppointments();
      if (isToday(selectedDate)) {
        const now = new Date();
        setMinTime(formatTime(now));
      } else {
        setMinTime("00:00"); // Reset minTime to default value
      }
  }, [selectedDate]);

  // const isEmpty = (obj) => {
  //   return Object.keys(obj).length === 0;
  // };
    const fetchAppointments = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${BASE_URL}/appointments/${id}`);
            let appointments = await response.data;
            const providersResponse = await axios.get(`${BASE_URL}/providers`);
            let providers = providersResponse.data;
            appointments = appointments.map(appointment => {
              const provider = providers.find(provider => provider.id === appointment.healthcare_provider_id);
              return { ...appointment, provider_name: provider ? provider.provider_name : 'HealthCare Provider' };
   
          }); 
          appointments = appointments.map(appointment => {
            const date = new Date(`1970-01-01T${appointment.time}Z`);
            const hours = date.getUTCHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const hours12 = hours % 12 || 12;

            const timeString = `${hours12}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;

            return {
              ...appointment,
              time: timeString,
            };
          });
          setAppointments(appointments);
          setIsLoading(false);
        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    };
    const fetchProviders = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(`${BASE_URL}/providers`);
          setProviders(response.data);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching providers:', error);
          if (error.response) {
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
        }
      };
    const openCreateModal = () => setCreateModalIsOpen(true);
    const closeCreateModal = () => {
      setCreateModalIsOpen(false);
      // setErrors({ provider: '', date: '', time: '', location: '' });
    }

    const handleCreateAppointment = async (event) => {
        event.preventDefault();
        // validateForm();
        // if (isEmpty(errors)) {
        const formData = new FormData(event.target);
        const provider = providers.find(provider => provider.id === Number(selectedProvider));
        const provider_name = provider ? provider.provider_name : '';
        
        try {
            const newAppointment = {
                user_id: id,
                healthcare_provider_id: selectedProvider,
                provider_name: provider_name,
                date: selectedDate.toISOString().split('T')[0],
                time: formData.get('time'),
                location: formData.get('location'),
                status: 'Scheduled'
            };
            const response = await axios.post(BASE_URL + '/appointments', newAppointment);
            setAppointments([...appointments, response.data]);
            fetchAppointments();
            closeCreateModal();
        } catch (error) {
            console.error('Error creating appointment:', error);
        }
      // }
    };

    const openRescheduleModal = (appointment) => {
        setSelectedAppointment(appointment);
        setRescheduleModalIsOpen(true);
    };
    const closeRescheduleModal = () => {
      setRescheduleModalIsOpen(false);
      // setRescheduleErrors({ provider: '', date: '', time: '', location: '' });
    }

    const handleRescheduleAppointment = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      // validateRescheduleForm();
      // if (isEmpty(rescheduleErrors)) {
        if (!selectedAppointment) return;
        
        try {
            const updatedAppointment = {
                date: selectedDate.toISOString().split('T')[0],
                time: formData.get('time'),
                location: selectedLocation,
                status: 'Rescheduled'
            };
            const response = await axios.put(`${BASE_URL}/appointments/${selectedAppointment.id}`, updatedAppointment);
            const updatedAppointments = appointments.map(app => app.id === response.data.id ? response.data : app);
            setAppointments(updatedAppointments);
            fetchAppointments();
            closeRescheduleModal();
        } catch (error) {
            console.error('Error rescheduling appointment:', error);
        }
      // }
    };
  
    const handleAppointmentClick = (appointment) => {
      setSelectedAppointment(appointment);
    };

    const openCancelModal = (appointment) => {
        setSelectedAppointment(appointment);
        setCancelModalIsOpen(true);
    };
    const closeCancelModal = () => setCancelModalIsOpen(false);

    const handleConfirmCancel = async () => {
        if (!selectedAppointment) return;
        try {
            await axios.delete(`${BASE_URL}/appointments/${selectedAppointment.id}`);
            setAppointments(appointments.filter(app => app.id !== selectedAppointment.id));
            closeCancelModal();
        } catch (error) {
            console.error('Error cancelling appointment:', error);
        }
    };
    const isToday = (date) => {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    };
  
    // Function to format the current time
    const formatTime = (time) => {
      const hours = time.getHours().toString().padStart(2, "0");
      const minutes = time.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    };
  
    // Handle change in the selected date
    const handleDateChange = (date) => {
      setSelectedDate(date);
      // Update minTime based on whether the selected date is today
      if (isToday(date)) {
        const now = new Date();
        setMinTime(formatTime(now));
      } else {
        setMinTime("00:00"); // Reset minTime to default value
      }
    };
    return (
      <div
        className="patient-appointments-component"
        style={{ position: "relative" }}
      >
        <button onClick={openCreateModal} className="button-create-schedule">
          <FontAwesomeIcon icon={faCalendarPlus} /> Schedule New Appointment
        </button>
        <Modal
          isOpen={createModalIsOpen}
          onRequestClose={closeCreateModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              border: "1px solid rgb(204, 204, 204)",
              background: "rgb(255, 255, 255)",
              overflow: "auto",
              borderRadius: "4px",
              outline: "none",
              padding: "20px",
              width: "40%",
              height: "auto",
            },
          }}
          contentLabel="Schedule New Appointment Modal"
        >
          <h2 style={{ marginBottom: "30px", color: "#005a8d" }}>Schedule New Appointment</h2>
          <form
            onSubmit={handleCreateAppointment}
            style={{ display: "grid", gap: "20px" }}
          >
            {["Provider Name", "Date", "Time", "Location"].map(
              (labelText, index) => (
                <div
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label>{labelText}:</label>
                  {labelText === "Provider Name" && (
                    <>
                      <select
                        onChange={(e) => setSelectedProvider(e.target.value)}
                        required
                        style={{
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                      >
                        <option value="">Select Provider</option>
                        {providers.map((provider) => (
                          <option key={provider.id} value={provider.id}>
                            {provider.provider_name}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  {labelText === "Date" && (
                    <>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      minDate={new Date()}
                      dateFormat="MMMM d, yyyy"
                      wrapperClassName="datePicker"
                    />
                    </>
                  )}
                  {labelText === "Time" && (
                    <>
                    <input
                      type="time"
                      name="time"
                      required
                      min={minTime} // Set the minimum time dynamically
                      style={{
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                      onChange={(e) => {
                        // Update minTime when the time input is touched
                        if (isToday(selectedDate)) {
                          const now = new Date();
                          setMinTime(formatTime(now));
                        }
                      }}
                    />
                    </>
                  )}
                  {labelText === "Location" && (
                    <>
                    <input
                      type="text"
                      name="location"
                      required
                      onChange={(e) => setSelectedLocation(e.target.value)}
                      style={{
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                    />
                    </>
                  )}
                </div>
              )
            )}
            <div
              className="modal-buttons"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button type="button" onClick={closeCreateModal}>
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </button>
              <button type="submit">
                <FontAwesomeIcon icon={faCalendarAlt} /> Schedule
              </button>
            </div>
          </form>
        </Modal>
        <h2>Appointments</h2>
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : appointments.length > 0 ? (
          <div className="patient-appointments-grid">
            {appointments.map((appointment) => (
              <div
                key={appointment.id}
                className="appointment-card"
                onClick={() => handleAppointmentClick(appointment)}
              >
                <h3>{`Appointment with ${appointment.provider_name}`}</h3>
                <p>
                  <strong>Date:</strong> {appointment.date}
                </p>
                <p>
                  <strong>Time:</strong> {appointment.time}
                </p>
                <p>
                  <strong>Location:</strong> {appointment.location}
                </p>
                <div className="appointment-actions">
                  <button
                    className="button-reschedule"
                    onClick={() => openRescheduleModal(appointment)}
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} /> Reschedule
                  </button>
                  <button
                    className="button-cancel"
                    onClick={() => openCancelModal(appointment)}
                  >
                    <FontAwesomeIcon icon={faTimes} /> Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No upcoming appointments.</p>
        )}

        {/* Reschedule Modal */}
        <Modal
          isOpen={rescheduleModalIsOpen}
          onRequestClose={closeRescheduleModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              border: "1px solid rgb(204, 204, 204)",
              background: "rgb(255, 255, 255)",
              overflow: "auto",
              borderRadius: "4px",
              outline: "none",
              padding: "20px",
              width: "30%",
              height: "auto",
            },
          }}
          contentLabel="Reschedule Appointment Modal"
        >
          <h2 style={{ marginBottom: "30px", color: "#005a8d" }}>Reschedule Appointment</h2>
          <form
            onSubmit={handleRescheduleAppointment}
            style={{ display: "grid", gap: "20px" }}
          >
            {["Date", "Time", "Location"].map((labelText, index) => (
              <div
                key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label>{labelText}:</label>
                {labelText === "Date" && (
                  <>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    minDate={new Date()}
                    dateFormat="MMMM d, yyyy"
                    wrapperClassName="datePicker"
                  />
                    </>
                )}
                {labelText === "Time" && (
                  <>
                  <input
                    type="time"
                    name="time"
                    required
                    min={minTime} // Set the minimum time dynamically
                    style={{
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                    onChange={(e) => {
                      // Update minTime when the time input is touched
                      if (isToday(selectedDate)) {
                        const now = new Date();
                        setMinTime(formatTime(now));
                      }
                    }}
                  />
                    </>
                )}
                {labelText === "Location" && (
                  <>
                  <input
                    type="text"
                    required
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    style={{
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                </>
                )}
                
              </div>
            ))}
            <div
              className="modal-buttons"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button onClick={closeRescheduleModal}>
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </button>
              <button type="submit">
                <FontAwesomeIcon icon={faCalendarAlt} /> Reschedule
              </button>
            </div>
          </form>
        </Modal>

        {/* Cancel Modal */}
        <Modal
          isOpen={cancelModalIsOpen}
          onRequestClose={closeCancelModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              border: "1px solid rgb(204, 204, 204)",
              background: "rgb(255, 255, 255)",
              overflow: "auto",
              borderRadius: "4px",
              outline: "none",
              padding: "20px",
              width: "30%",
              height: "auto",
            },
          }}
          contentLabel="Cancel Appointment Modal"
        >
          <h2 style={{marginBottom: "20px", color: "#005a8d" }}>Confirm Appointment Cancellation</h2>
          <p>Are you sure you want to cancel the appointment?</p>
          <div className="button-container">
            <button onClick={handleConfirmCancel} className="update_button">
              Yes, Cancel It
            </button>
            <button onClick={closeCancelModal} className="close_button">
              <FontAwesomeIcon icon={faTimes} /> No
            </button>
          </div>
        </Modal>
      </div>
    );

}
export default PatientAppointmentsComponent;

