import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/RegistrationPage.css';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, REGISTER } from '../utils';
import emailjs from 'emailjs-com';

const RegistrationPage = () => {
    const navigate = useNavigate();
    const [registrationAllowed, setRegistrationAllowed] = useState(false);

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        password: '',
        confirmPassword: '',
        dateOfBirth: '',
        address: '',
        phoneNumber: '',
        role: ''
    });
    const [errors, setErrors] = useState({});
    const [emailError, setEmailError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [users, setUsers] = useState([]);
    useEffect(() => {
        fetchUsers();
        checkRegistrationStatus();
    }, []);
    const getErrorMessage = (response) => {
        if (response.error && response.messages) {
            const messages = Object.values(response.messages).flat().join(', ');
            return `Error: ${messages}`;
        }
        return response;
    };

    const checkRegistrationStatus = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/maintenances/1`);
            setRegistrationAllowed(response.data.status === "Yes");
        } catch (error) {
            console.error('Failed to fetch registration status:', error);
        }
    };


    const fetchUsers = async () => {
        try {
            const usersResponse = await axios.get(`${BASE_URL}/users`);
            const usersData = usersResponse.data;
            setUsers(usersData.users);
        } catch (error) {
            console.error('Failed to fetch users and appointments:', error);
        }
    };

    const validateForm = () => {
        let tempErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\d{10}$/;

        // Validate full name
        tempErrors.fullName = formData.fullName ? "" : "Full name is required.";

        // Validate email with regex
        tempErrors.email = emailRegex.test(formData.email) ? "" : "Email is not valid.";

        // Validate password (add rules as needed, example: minimum 8 characters)
        tempErrors.password = formData.password.length >= 8 ? "" : "Password must be at least 8 characters.";

        // Check if passwords match
        tempErrors.confirmPassword = formData.confirmPassword === formData.password ? "" : "Passwords do not match.";

        // Validate date of birth
        tempErrors.dateOfBirth = formData.dateOfBirth ? "" : "Date of birth is required.";

        // Validate address
        tempErrors.address = formData.address ? "" : "Address is required.";

        // Validate phone number with regex
        tempErrors.phoneNumber = phoneRegex.test(formData.phoneNumber) ? "" : "Phone number must be 10 digits.";

        // Validate role
        tempErrors.role = formData.role ? "" : "Role is required.";

        // Set the state with errors
        setErrors({ ...tempErrors });

        // If no error messages, form is valid
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!registrationAllowed) {
            setModalMessage('Registrations are currently closed. Please try again later.');
            setIsModalOpen(true);
            return;
        }
        if (validateForm()) {
            try {
                const url = BASE_URL + REGISTER;
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: formData.fullName,
                        email: formData.email,
                        password: formData.password,
                        date_of_birth: formData.dateOfBirth,
                        address: formData.address,
                        phone_number: formData.phoneNumber,
                        role: formData.role,
                    })
                });


                if (response.ok) {
                    const data = await response.json();

                    emailjs.send('service_maxiv28', 'template_8akpe24', {
                        email: String(formData.email),
                    }, 'g4A6CHojcuRr5PqXA')
                        .then(function (response) {
                            console.log('SUCCESS!', response.status, response.text);
                        }, function (error) {
                            console.log('FAILED...', error);
                        });
                    setModalMessage('Registration successful! Please verify your email to continue.');
                    localStorage.setItem('isLoggedIn', 'true');
                    localStorage.setItem('userRole', data.user.role);
                    localStorage.setItem('id', data.user.id);

                    setIsModalOpen(true);
                    setTimeout(() => {
                        navigate('/')
                    }, 2000);
                } else {
                    const data = await response.json();
                    if (data && data.messages) {
                        const messages = Object.values(data.messages).flat().join(', ');
                        setModalMessage(`Error: ${messages}`);
                    } else {
                        setModalMessage('Failed to register. Please try again later.');
                    }
                    setIsModalOpen(true);
                }
            } catch (error) {
                console.error('Error:', error);
                setModalMessage(`Error: ${error.message}`);
                setIsModalOpen(true);
            }
        } else {
            console.log("Form is invalid!");
        }
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        if (name === 'email') {
            const isEmailExist = users.some(user => user.email === value);
            if (isEmailExist) {
                setEmailError('Email already exists.');
            } else {
                setEmailError('');
            }
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="registration-container">
            <form onSubmit={handleSubmit} noValidate>
                <h2>Sign Up</h2>
                <div className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <input type="text" name="fullName" id="fullName" value={formData.fullName} onChange={handleChange} />
                    {errors.fullName && <p className="error">{errors.fullName}</p>}
                </div>
                <div className="form-group">
                    <input type="hidden" name="patient" value="Patient" />
                    <input type="hidden" name="healthcareProvider" value="Healthcare Provider" />
                    <input type="hidden" name="administrator" value="Administrator" />
                    <input type="hidden" name="pharmacist" value="Pharmacist" />
                    <input type="hidden" name="healthAdministrator" value="Health Administrator" />
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" value={formData.email} onChange={handleChange} />
                    {errors.email && <p className="error">{errors.email}</p>}
                    {emailError && <p className="error">{emailError}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="role">Role</label>
                    <select name="role" id="role" value={formData.role} onChange={handleChange}>
                        <option value="">Select Role</option>
                        <option value="Patient">Patient</option>
                        <option value="Healthcare Provider">Healthcare Provider</option>
                        {/* <option value="Administrator">Administrator</option> */}
                        <option value="Pharmacist">Pharmacist</option>
                        {/* <option value="Health Administrator">Health Administrator</option> */}
                    </select>
                    {errors.role && <p className="error">{errors.role}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" id="password" value={formData.password} onChange={handleChange} />
                    {errors.password && <p className="error">{errors.password}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input type="password" name="confirmPassword" id="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
                    {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="dateOfBirth">Date of Birth</label>
                    <input type="date" name="dateOfBirth" id="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange}  max={new Date().toISOString().split('T')[0]}/>
                    {errors.dateOfBirth && <p className="error">{errors.dateOfBirth}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input type="text" name="address" id="address" value={formData.address} onChange={handleChange} />
                    {errors.address && <p className="error">{errors.address}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input type="text" name="phoneNumber" id="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
                    {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
                </div>
                <div className="form-actions">
                    <button type="submit">Register</button>
                    <button type="button" onClick={() => window.location.href = "/login"}>Already have an account?</button>
                </div>
            </form>
            <Modal
                isOpen={isModalOpen}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: '1px solid rgb(204, 204, 204)',
                        background: 'rgb(255, 255, 255)',
                        overflow: 'auto',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px',
                        width: '30%',
                        height: '30%',
                    }
                }}
                onRequestClose={handleModalClose}
                contentLabel="Email Verification"
                ariaHideApp={false}
            >
                <h2>Email Verification</h2>
                <p>{modalMessage}</p>
                <button onClick={handleModalClose}>Close</button>
            </Modal>
        </div>
    );
};

export default RegistrationPage;
