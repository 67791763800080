import React, { useState } from 'react';
import '../styles/ProfilePage.css';

const ProfilePage = () => {
    const [userProfile, setUserProfile] = useState({
        fullName: '',
        email: '',
        role: '',
        address: '',
        phoneNumber: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserProfile({ ...userProfile, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(userProfile);
    };

    return (
        <div className="profile-container">
            <form onSubmit={handleSubmit}>
                <h2>User Profile</h2>
                <div className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <input
                        type="text"
                        name="fullName"
                        id="fullName"
                        value={userProfile.fullName}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={userProfile.email}
                        onChange={handleChange}
                        disabled
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input
                        type="text"
                        name="address"
                        id="address"
                        value={userProfile.address}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                        type="tel"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={userProfile.phoneNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-actions">
                    <button type="submit">Update Profile</button>
                </div>
            </form>
        </div>
    );
};

export default ProfilePage;
