import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../styles/admin.css';
import { BASE_URL } from '../../utils';

const DataReportingComponent = () => {
    const [userData, setUserData] = useState([]);
    const [providerData, setProviderData] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [loadingProviders, setLoadingProviders] = useState(true);

    useEffect(() => {
        fetchTodayUsers();
        fetchProviders();
    }, []);

    const fetchTodayUsers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/users`);
            setUserData(response.data.users.filter(user => {
                const userDate = new Date(user.created_at);
                const today = new Date();
                return userDate.toDateString() === today.toDateString();
            }));
            setLoadingUsers(false);
        } catch (error) {
            console.error('Failed to fetch today\'s users:', error);
            setLoadingUsers(false);
        }
    };

    const fetchProviders = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/providers`);
            setProviderData(response.data);
            setLoadingProviders(false);
        } catch (error) {
            console.error('Failed to fetch provider data:', error);
            setLoadingProviders(false);
        }
    };

    const downloadCSV = (data, filename) => {
        const csvRows = [
            "id,name,email,dateOfBirth,address,phoneNumber,role,createdAt",
            ...data.map(user => `${user.id},${user.name},${user.email},${user.date_of_birth},${user.address},${user.phone_number},${user.role},${user.created_at}`)
        ];
        const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${filename}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const downloadProviderCSV = (data, filename) => {
        const csvRows = [
            "id,provider_name,location,specialty,rating",
            ...data.map(user => `${user.id},${user.provider_name},${user.location},${user.specialty},${user.rating}`)
        ];
        const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${filename}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Tabs>
            <TabList>
                <Tab>Daily User Data</Tab>
                <Tab>Ratings</Tab>
            </TabList>

            <TabPanel>
                <h2>Today's Registered Users</h2>
                {loadingUsers ? <p>Loading...</p> : (
                    <>
                        <button onClick={() => downloadCSV(userData, 'daily_user_data')}>Download Today's User Data</button>
                        {userData.map(user => (
                            <div key={user.id}>
                                <h3>{user.name}</h3>
                                <p>Email: {user.email}</p>
                                <p>Role: {user.role}</p>
                            </div>
                        ))}
                    </>
                )}
            </TabPanel>

            <TabPanel>
                <h2>Provider Ratings</h2>
                {loadingProviders ? <p>Loading...</p> : (
                    <>
                        <button onClick={() => downloadProviderCSV(providerData, 'provider_ratings')}>Download Provider Ratings</button>
                        {providerData.map(provider => (
                            <div key={provider.id}>
                                <h3>{provider.provider_name}</h3>
                                <p>Specialty: {provider.specialty}</p>
                                <p>Location: {provider.location}</p>
                                <p>Rating: {provider.rating}</p>
                            </div>
                        ))}
                    </>
                )}
            </TabPanel>
        </Tabs>
    );
};

export default DataReportingComponent;